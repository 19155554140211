import React from 'react'
// Customizable Area Start
import TrainerYouMatterController, { BucketData, Fields, QuestionsType, S } from './TrainerMpowerYouMatterController.web';
import SidebarLayout from '../../SidebarLayout.web';
import PageContentLayout from '../../PageContentLayout.web';
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Grid, Checkbox, TextField, Accordion, Button, AccordionSummary, AccordionDetails, Tabs, Tab } from "@material-ui/core"
import { QuestionListLayout } from './TrainerMpowerYouMatterQuestionList.web';
import { blueCheckboxCheckedIcon, blueCheckboxIcon, blueDownArrow } from '../../assets';
import { useStyles as youMatterQuestionStyles } from '../../YouMatter/YouMatterQuestions.web';
import RootContext from '../../../../../components/src/RootContext';
import InfoModal from '../../CommonComponents/InformationModal.web';
import Loader from '../../../../../components/src/Loader.web';

const configJSON = require("../../config.js");
// Customizable Area End
export const useStyles = makeStyles(() => ({
// Customizable Area Start
    pageWrapper: {
        padding: '32px 24px 24px 24px '
    },
    generalNote: {
        border: '0.2px solid #e3e3e3',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.122705)',
        borderRadius: '8px',
        marginBottom: '20px',
        padding: '10px'
    },
    accordion: {
        '&.MuiAccordion-root': {
            boxShadow: 'none'
        },
        '&.MuiAccordion-root:before': {
            display: 'none'
        }
    },
    accordionSummary: {
        '& .MuiAccordionSummary-content': {
            padding: '0',
            minHeight: 'auto',
            display: 'block',
            margin: '5px 0 5px 9px'
        },
        '&.MuiAccordionSummary-root': {
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.122705)',
            borderRadius: '8px',
            border: '0.2px solid #e3e3e3',
            marginBottom: '12px',
            padding: '0px 40px 0px 0px'
        }
    },
    accordionDetails: {
        '&.MuiAccordionDetails-root': {
            display: 'block',
            padding: '10px 0 0 0'
        }
    },
    dragAndDropOptionsLabelWrapper: {
        marginBottom: "16px",
    },
    dragAndDropOptionsLabel: {
        fontWeight: "bold",
        color: '#0057A4',
    },
    questionCard: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: '55px',
        '@media(max-width:450px)': {
            marginRight: '10px',
        }
    },
    questionText: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#3B3B3B',
        fontFamily: "Poppins",
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkitLineClamp': 2,
        '-webkitBoxOrient': 'vertical',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
        '&:hover': {
            '-webkitBoxOrient': 'inherit',
            wordBreak: 'break-word',
        }
    },
    headerEnd: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '150px',
        '@media(max-width:450px)': {
            maxWidth: '100px',
        }
    },
    questionWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    optionContent: {
        overflow: 'hidden',
        marginRight: '10px',
        display: '-webkit-box',
        '-webkitLineClamp': 1,
        '-webkitBoxOrient': 'vertical',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
        '&:hover': {
            '-webkitBoxOrient': 'inherit',
            wordBreak: 'break-word',
        }
    },
    option: {
        minHeight: '48px',
        minWidth: '48px',
        borderRadius: '8px',
        backgroundColor: '#0056A3',
        color: 'white',
        fontSize: '24px',
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '20px',
        fontFamily: "Poppins",
    },
    optionWrapper: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
        borderRadius: '8px'
    },
    activeOption: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
        border: '1px solid #0056A3',
        borderRadius: '8px'
    },
    checkBox: {
        '&.MuiCheckbox-root': {
            marginRight: '10px'
        }
    },
    nextBtn: {
        "&.MuiButton-root": {
            height: '48px',
            width: '160px',
            borderRadius: "8px",
            backgroundColor: "#0056A3",
            display: 'block',
            marginLeft: 'auto'
        },
        '& .MuiButton-label': {
            textTransform: 'capitalize',
            color: 'white',
            fontFamily: "Poppins",
        },
        '&.MuiButton-root.Mui-disabled': {
            backgroundColor: '#0056a380'
        }
    },
    subjectiveInput: {
        '&.MuiFormControl-root': {
            width: '100%'
        },
        '& .MuiInputBase-input': {
            padding: '24px 22px',
            background: '#FDFDFD',
            border: '0.2px solid #0056A3',
            borderRadius: '8px'
        },
        '& .MuiInput-underline:before': {
            display: 'none'
        },
        '& .MuiInput-underline:after': {
            display: 'none'
        }
    },
// Customizable Area End
}))
// Customizable Area Start
const renderIcon = (name: string) => {
    switch (name) {
        case configJSON.TrainerMPowerYouMatter.Checkbox: return <img src={blueCheckboxIcon} alt="unchecked" />
        case configJSON.TrainerMPowerYouMatter.CheckboxChecked: return <img src={blueCheckboxCheckedIcon} alt="checked" />
        case configJSON.TrainerMPowerYouMatter.DownArrow: return <img src={blueDownArrow} alt="down_arrow" />
    }
}

export const optionName = (id: number) => {
    switch (id) {
        case 1: return configJSON.TrainerMPowerYouMatter.Option.A;
        case 2: return configJSON.TrainerMPowerYouMatter.Option.B;
        case 3: return configJSON.TrainerMPowerYouMatter.Option.C;
        case 4: return configJSON.TrainerMPowerYouMatter.Option.D;
        case 5: return configJSON.TrainerMPowerYouMatter.Option.E;
        case 6: return configJSON.TrainerMPowerYouMatter.Option.F;
    }
}
// Customizable Area End

// Customizable Area Start
interface TrainerMpowerYouMatterQuestionBankPageProps {
    sectionId: number;
    totalQuestions: number;
    fields: Fields[];
    isYouMatter: boolean;
    handleCheck: (event: any, content: string, type: string, sectionId: number, totalQuestions: number, context: any) => void;
    goTo: (module: string, params?: Object) => void;
    states: S;
    context: any;
    handleTabChange: (_event: React.ChangeEvent<{}>, newValue: number) => void;
}
// Customizable Area End
export const TrainerMpowerYouMatterQuestionBankPage = (props: TrainerMpowerYouMatterQuestionBankPageProps) => {
    // Customizable Area Start
    const classes = useStyles();
    const commonClasses = youMatterQuestionStyles(props);

    const a11yProps = (index: any) => {
        return {
            id: `you-matter-tab-${index}`,
            'aria-controls': `you-matter-tabpanel-${index}`,
        };
    }
    const tabList = configJSON.TrainerMPowerYouMatter.QuestionBankTabList;
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const handleChangeAccordion = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };
    const isDisable = (questionId: any) => {
        for (let section of props.fields || []) {
            if (section.id !== props.sectionId && section.questions.find((question: any) => Number(question.id) === Number(questionId))) {
                return true;
            }
        }
        return false;
    }

    const isNextDisable = () => {
        const section = props.fields?.find((field: Fields) => field.id === props.sectionId);
        const questionsLength = section ? section.questions.length : 0;
        return props.totalQuestions > questionsLength;
    }

    const currentSection = props.fields?.filter((element: Fields) => element.id === props.sectionId)
    let questionArr = currentSection && currentSection[0].questions;
    const isChecked = (questionId: any) => {
        return questionArr?.some((question: QuestionsType) => Number(question.id) === Number(questionId));
    }

    const getQuestionType:(question_type:string) => string = (question_type:string) => {
        let questionType:string;
        if (question_type === configJSON.QuestionTypes.Objective){
            questionType = "MCQ's";
        } else if (question_type === configJSON.QuestionTypes.TextBased) {
            questionType = "Subjective";
        } else if (question_type == "puzzle") {
            questionType = "Puzzle";
        } else if(question_type === configJSON.QuestionTypes.DragAndDrop){
            questionType = "Drag & Drop"
        } else {
            questionType = "True/False";
        }

        return questionType;
    }

    return <>
        <Box className={classes.pageWrapper}>
            <Box className={classes.generalNote} data-test-id="generalised-noted">
                <Typography>{configJSON.TrainerMPowerYouMatter.GeneralisedNoteForOptions}</Typography>
            </Box>
            <Tabs className={commonClasses.tabs} value={props.states.tab} data-test-id='tabs' onChange={props.handleTabChange} variant="scrollable" scrollButtons="auto">
                {tabList.map((element: any, index: number) => {
                    return <Tab className={commonClasses.tab} label={element.name} {...a11yProps(index)} />
                })}
            </Tabs>
            <QuestionListLayout>
            {props.states.questionBankQuestions.length > 0 ? props.states.questionBankQuestions.map((element: any, index: number) => {
            const attributes = element.attributes
            let questionType:string = getQuestionType(attributes.question_type);
            return <Accordion key={attributes.id} data-test-id='accordion' className={classes.accordion} expanded={expanded === `${index + 1}`} onChange={handleChangeAccordion(`${index + 1}`)}>
                <AccordionSummary disabled={isDisable(attributes.id)} className={classes.accordionSummary} expandIcon={renderIcon('downArrow')} >
                    <Box className={classes.questionCard}>
                        <Box className={classes.questionWrapper}>
                            <Checkbox
                                checked={isChecked(attributes.id)}
                                className={classes.checkBox}
                                color="primary"
                                data-test-id='checkbox'
                                onClick={(e: any) => props.handleCheck(e, attributes.text, questionType, props.sectionId, props.totalQuestions, props.context)} value={attributes.id} checkedIcon={renderIcon('checkboxChecked')} icon={renderIcon('checkbox')} />
                            <Typography className={classes.questionText}>{attributes.text}</Typography>
                        </Box>
                        <Typography>{questionType}</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    {attributes.document_file && <Box className={commonClasses.questionImagePlaceholder}> <img src={attributes.document_file} alt="question details pic" /></Box>}
                    {attributes.question_type === configJSON.QuestionTypes.DragAndDrop && (
                        <Box className={classes.dragAndDropOptionsLabelWrapper}>
                            <Typography className={classes.dragAndDropOptionsLabel}>{configJSON.TrainerMPowerYouMatter.DragAndDropOptionsLabelTxt}</Typography>
                        </Box>
                    )}
                    {(attributes.question_type === 'objective' || attributes.question_type === 'true_false' || attributes.question_type === 'drag_and_drop') && <Grid container spacing={2}>
                        {
                            attributes.choice.map((element: any, index: number) => {
                                return <Grid key={element.id} item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Box className={element.is_correct ? classes.activeOption : classes.optionWrapper}>
                                        <Box className={classes.option}>{optionName(index + 1)}</Box>
                                        <Typography className={classes.optionContent} >{element.content}</Typography>
                                    </Box>
                                </Grid>
                            })
                        }
                    </Grid>}
                    
                    {attributes.question_type === configJSON.QuestionTypes.TextBased && <TextField className={classes.subjectiveInput} value={attributes.correct_answer} disabled multiline minRows={5} />}
                    {attributes.question_type === configJSON.QuestionTypes.DragAndDrop && (
                        <Box data-test-id="drag_and_drop_buckets" mt={5} sx={{ flexGrow: 1 }} display="flex" flexDirection="column" justifyContent="center">
                            <Box textAlign="center" mb={5}>
                                <Typography style={{ color: "#94A3B8", fontWeight: 500 }}>{configJSON.TrainerMPowerYouMatter.DragAndDropBucketInfoTxt}</Typography>
                            </Box>
                            <Grid container spacing={10}>
                                {attributes?.buckets && attributes.buckets?.data?.map((bucket: BucketData) => {
                                    return (
                                    <Grid key={bucket.id} item xs={6}>
                                        <Box height={200} mb={2} display="flex" alignItems="center" sx={{ border: '2px solid #CBD5E1', borderRadius: '8px' }}>
                                        </Box>
                                        <Box textAlign="center">
                                            <Typography style={{ fontWeight: 600, fontSize: '16px', lineHeight: "24px" }}>{bucket.attributes.content}</Typography>
                                        </Box>
                                    </Grid>
                                    )
                                })}
                            </Grid>
                        </Box>
                    )}
                </AccordionDetails>
            </Accordion>
        }) : configJSON.TrainerMPowerYouMatter.NoQuestions}
            </QuestionListLayout>
            <Button className={classes.nextBtn} data-test-id='next' disabled={isNextDisable()} onClick={() => props.goTo(props.isYouMatter ? configJSON.TrainerMPowerYouMatter.TrainerYouMatterQuestionList : configJSON.TrainerMPowerYouMatter.TrainerMpowerQuestionList)} >{configJSON.ButtonTexts.Next}</Button>
        </Box>
    </>;
    // Customizable Area End
}

export default class TrainerMpowerYouMatterQuestionBank extends TrainerYouMatterController {
    // Customizable Area Start
    static contextType = RootContext
    async componentDidMount() {
        window.addEventListener("beforeunload", this.unloadCallback);
        window.addEventListener("unload", this.handleUnload);
        const isReload = localStorage.getItem(configJSON.TrainerMPowerYouMatter.IsReload)
        if (isReload) {
            localStorage.removeItem(configJSON.TrainerMPowerYouMatter.IsReload)
            const path = window.location.pathname;
            const isYouMatter = path.includes(configJSON.TrainerMPowerYouMatter.YouMatterPath)
            this.goTo(isYouMatter ? configJSON.TrainerMPowerYouMatter.TrainerYouMatterCheck : configJSON.TrainerMPowerYouMatter.TrainerMpowerCheck)
        }
        this.getQuestions(this.state.questionBankQueType, this.context?.formData)
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<S>): void {
        if (prevState.questionBankQueType != this.state.questionBankQueType) {
            this.getQuestions(this.state.questionBankQueType, this.context?.formData)
        }
    }

    async componentWillUnmount() {
        localStorage.removeItem(configJSON.TrainerMPowerYouMatter.IsReload)
        window.removeEventListener("beforeunload", this.unloadCallback);
        window.removeEventListener("unload", this.handleUnload);
    }

    render() {
        const path = window.location.pathname;
        const isYouMatter = path.includes(configJSON.TrainerMPowerYouMatter.YouMatterPath)
        const sectionId = Number(this.props.navigation.getParam(configJSON.TrainerMPowerYouMatter.SectionId));
        const totalQuestions = Number(this.props.navigation.getParam(configJSON.TrainerMPowerYouMatter.TotalQuestions));
        const fields = this.context?.formData?.addFormData?.fields;
        const courseList = this.context?.formData?.addFormCourseList;
        const sectionList = this.context?.formData?.addFormSectionList;
        const courseId = this.context?.formData?.addFormData?.courseName;
        const gradeId = this.context?.formData?.addFormData?.sectionName;
        const selectedCourse = courseList?.filter((element: any) => element.id === courseId);
        const selectedSection = sectionList?.filter((element: any) => element.id === gradeId);
        const questionBankFilterProps = {
            courseName: selectedCourse && selectedCourse[0]?.name,
            sectionName: selectedSection && selectedSection[0]?.name
        }

        return <>
            <SidebarLayout {...this.props}>
                <PageContentLayout title={configJSON.TrainerMPowerYouMatter.QuestionBank} questionBankFilters={questionBankFilterProps} goBack={this.goBack}>
                    {this.state.isLoading ? <Loader loading={this.state.isLoading} /> : null}
                    <TrainerMpowerYouMatterQuestionBankPage
                        sectionId={sectionId}
                        totalQuestions={totalQuestions}
                        fields={fields}
                        handleCheck={this.handleQuestionCheck}
                        goTo={this.goTo}
                        states={this.state}
                        context={this.context}
                        isYouMatter={isYouMatter}
                        handleTabChange={this.handleTabChange}
                    />
                    <InfoModal
                        title={configJSON.TrainerMPowerYouMatter.Alert}
                        info={`You cannot add more than ${totalQuestions} Questions`}
                        modalType={this.state.modalType}
                        open={this.state.openModal}
                        closeModal={() => this.closeWarningModal(sectionId, this.context)} />
                </PageContentLayout>
            </SidebarLayout>
        </>
    }
    // Customizable Area End
}