import React from 'react'
// Customizable Area Start
import SidebarLayout from "../../SidebarLayout.web";
import { Box, Grid, Typography, Button, IconButton, styled } from "@material-ui/core";
import { useStyles } from "../StudentsReport/StudentsReportList.web";
import CommonPagination from '../../../../../components/src/Pagination.web';
import ProgramImpactReportController, { configJSON } from './ProgramImpactReportController.web';
import { addImpactStoryBtn, deleteIcon, editPenBlue, sheetGrey, sheetBlue, deleteAlertIcon } from "../../assets"
import "./ProgramImpactReport.css";
import PageContentLayout from '../../PageContentLayout.web';
import commonStyles from '../../commonStyles.web';
import CommonTextField from '../../CommonComponents/CommonTextField.web';
import CommonSelect from '../../CommonComponents/CommonSelect.web';
import InfoModal from '../../CommonComponents/InformationModal.web';
import CommonSnackBar from '../../CommonComponents/CommonSnackBar.web';

const tableHeader = configJSON.ImpactReport.TableHeader;
// Customizable Area End

// Customizable Area Start
export const ProgramImpactReportPage = (props: any) => {
    const classes = useStyles();
    const commonClasses = commonStyles({});
    const nuberedPages = props.data.count / configJSON.pageElementCount;

    return <>
        <Box className={classes.pageWrapper}>
            <Box className={commonClasses.tableWrapper} >
                <Box>
                    <Grid container className={commonClasses.tableHead}>
                        {tableHeader.map((element: any) => (<Grid key={element} item xs={true} className={commonClasses.cell} >{element}</Grid>))}
                    </Grid>
                    <Grid>
                        {props.data.reports?.slice((props.pageNo - 1) * 10, 10)?.map((row: any) => {
                            const attributes = row.attributes;
                            return <Grid key={attributes.student_id} container className={commonClasses.tableRow} >
                                <Grid item xs={true} className={commonClasses.cell}>{attributes.mind_mastery_course.name}</Grid>
                                <Grid item xs={true} className={commonClasses.cell}>{attributes.school.name}</Grid>
                                <Grid item xs={true} className={commonClasses.cell}>{attributes.academic_year}</Grid>
                                <Grid item xs={true} className={`${commonClasses.cell} ${classes.editIconsContainerImpactReport}`} >
                                    <IconButton className={commonClasses.iconButton}>
                                        <a href={attributes.pdf_url} target="_blank" rel="noopener noreferrer">
                                            <img
                                                data-test-id="pdfIcon"
                                                src={attributes.pdf_url === null ? sheetGrey : sheetBlue} />
                                        </a>
                                    </IconButton>
                                    <IconButton className={commonClasses.iconButton}>
                                        <img
                                            data-test-id="editIcon"    
                                            onClick={() => { props.handleEditIconClick(row.id) }}
                                            src={editPenBlue} />
                                    </IconButton>
                                    <IconButton className={commonClasses.iconButton}>
                                        <img
                                            data-test-id="deleteIcon" 
                                            onClick={() => { props.handleDeleteIconClick(row.id) }}
                                            src={deleteIcon} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        })}
                    </Grid>
                </Box>
            </Box>
            <CommonPagination page={props.pageNo} handlePage={props.handlePageChange} pageCount={nuberedPages < 1 ? 1 : Math.ceil(nuberedPages)} />
        </Box>
    </>
}
    // Customizable Area End

export default class ProgramImpactReport extends ProgramImpactReportController {
    // Customizable Area Start
    renderNextButton() {
        return (
            <Button
                data-test-id="selectNextBtn"
                style={webStyle.nextButton}
                onClick={() => this.handleCreateClick()}
                className='nextButton'
            >
                {this.state.editMode ? "Update" : "Create"}
            </Button>
        )
    }
    renderPirForm = () => {
        return (<>
            <Box sx={webStyle.editWrapper} >
                <Grid spacing={3} container>
                    <Grid xs={12} item>
                        <CommonSelect
                            label={`${configJSON.ImpactReport.ProgramName}*`}
                            handleChange={(event: any) => this.handleInputChange(event, "coursePir")}
                            value={this.state.selectedCourse.name}
                            placeholder={configJSON.ImpactReport.EnterProgramName}
                            menuList={this.state.courseList}
                            disabled={!!this.state.editMode}
                            error={this.state.validationErrors.selectedCourse}
                            data-test-id="selectId"
                        />
                        {this.state.validationErrors.selectedCourse && <Typography style={{ color: "#f44336", fontSize: "12px", marginTop: "0.2vw" }}>* Please Select a Course!</Typography>}
                    </Grid>
                    <Grid xs={12} sm={6} item>
                        <CommonSelect
                            label={`${configJSON.ImpactReport.SchoolName}*`}
                            handleChange={(event: any) => this.handleInputChange(event, "schoolPir")}
                            value={this.state.selectedSchool.name}
                            menuList={this.state.schoolList}
                            disabled={!!this.state.editMode}
                            placeholder={configJSON.ImpactReport.EnterSchoolName}
                            error={this.state.validationErrors.selectedSchool}
                        />
                        {this.state.validationErrors.selectedSchool && <Typography style={{ color: "#f44336", fontSize: "12px", marginTop: "0.2vw" }}>* Please Select a School!</Typography>}
                    </Grid>
                    <Grid xs={12} sm={6} item>
                        <CommonSelect
                            label={`${configJSON.ImpactReport.AcademicYear}*`}
                            handleChange={(event: React.ChangeEvent<HTMLSelectElement>) => this.handleInputChange(event, "yearPir")}
                            value={this.state.academic_year}
                            menuList={this.state.academicYearList}
                            disabled={!!this.state.editMode}
                            placeholder={configJSON.ImpactReport.EnterAcademicYear}
                            error={this.state.validationErrors.academicYear}
                        />
                        {this.state.validationErrors.academicYear && <Typography style={webStyle.textColor} className='textColor' >* Please Select a academic year!</Typography>}
                    </Grid>
                    <Grid xs={12} item>
                        <CommonTextField
                            multiline
                            minRows={10}
                            mb={'0px'}
                            label={`${configJSON.ImpactReport.Overview}*`}
                            name={configJSON.ImpactReport.OverviewName}
                            value={this.state.selectedCourse.overview}
                            placeholder={configJSON.ImpactReport.Overview}
                            disabled={!!this.state.editMode}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <CommonTextField
                            multiline
                            minRows={10}
                            mb={'0px'}
                            label={`${configJSON.ImpactReport.ImpactMeasurement}*`}
                            name={configJSON.ImpactReport.ImpactMeasurementName}
                            value={this.state.impactMeasurement}
                            handleChange={(event: any) => this.handleInputChange(event, "impactMeasurement")}
                            placeholder={configJSON.ImpactReport.ImpactMeasurementPlaceholder}
                            data-test-id="InputField"
                            helperText={this.state.validationErrors.impactMeasurement && "* This field can't be left empty!"}
                            error={this.state.validationErrors.impactMeasurement}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <Typography className="skillNameTxt" component="span">
                            Stories of Impact*
                        </Typography>
                    </Grid>
                    {this.state.stories_of_impact.map((story: { id: number, story: string }) => (      
                        <Grid xs={12} key={story.id} item>
                            <CommonTextField
                                key={story.id}
                                mb={'0px'}
                                minRows={1}
                                name={configJSON.ImpactReport.StoryName}
                                value={story.story}
                                handleChange={(event: any) => this.handleStoryChange(event, story.id)}
                                placeholder={configJSON.ImpactReport.StoryPlaceholder}
                                data-test-id="storyOfImpactTestId"
                            />
                        </Grid>
                    ))}
                    <Grid xs={12} item>{this.state.validationErrors.storisOfImpact && <Typography style={{ color: "#f44336", fontSize: "12px" }}>* This field can't be left empty!</Typography>}</Grid>
                    <Grid>{this.state.validationErrors.storiesOfImpactLength && <ErrorMsg>{configJSON.ImpactReport.ErrorMessageForStoryImpact}</ErrorMsg>}</Grid>
                    <Grid xs={12} item><img data-test-id="addStoryBtn" onClick={() => this.handleAddNewStory()} className='addImpactStoryBtn' src={addImpactStoryBtn}></img>
                    </Grid>
                    <Grid xs={12} item>
                        <div className='subTitleContainer'>Message From Our Educators</div>
                    </Grid>
                    {this.state.message_from_our_educators.map((message: any) => {
                        return (
                            <Grid key={message.id} xs={12} item>
                                <CommonTextField
                                    multiline
                                    minRows={5}
                                    mb={'0px'}
                                    name={configJSON.ImpactReport.Msg}
                                    value={message.message}
                                    handleChange={(event: any) => this.handleEducatorMessageChange(event, message.id)}
                                    placeholder={configJSON.ImpactReport.EnterMessage}
                                    data-test-id="InputField"
                                />
                            </Grid>
                        )
                    })}
                    <Grid xs={12} item>
                        {this.state.validationErrors.messages && <Typography style={{ color: "#f44336", fontSize: "12px" }}>* This field can't be left empty!</Typography>}
                    </Grid>
                    <Grid>{this.state.validationErrors.messageFromEducatorLength && <ErrorMsg>{configJSON.ImpactReport.ErrorMessageForEducator}</ErrorMsg>}</Grid>
                    <Grid xs={12} item>
                        <div className='subTitleContainer'>{configJSON.ImpactReport.feedbackName}</div>
                    </Grid>
                    <Grid xs={12} item>
                        <CommonTextField
                            multiline
                            minRows={5}
                            mb={'0px'}
                            name={configJSON.ImpactReport.FeedbackName}
                            value={this.state.feedback_and_improvements}
                            handleChange={(event: any) => this.handleInputChange(event, "feedback")}
                            placeholder={configJSON.ImpactReport.EnterFeedback}
                            data-test-id="feedbackTestId"
                            helperText={this.state.validationErrors.feedback && "* This field can't be left empty!" }
                            error={this.state.validationErrors.feedback}
                        />
                    </Grid>
                    <Grid>{this.state.validationErrors.feedbackLength && <ErrorMsg>{configJSON.ImpactReport.ErrorMessageForFeedback}</ErrorMsg>}</Grid>
                    <Grid xs={12} item>
                        <div className='subTitleContainer'>{configJSON.ImpactReport.improvementHead}</div>
                    </Grid>
                    <Grid xs={12} item>
                        <CommonTextField
                            multiline
                            minRows={5}
                            mb={'0px'}
                            name={configJSON.ImpactReport.improvementName}
                            value={this.state.improvement}
                            handleChange={(event:React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(event, "improvement")}
                            placeholder={configJSON.ImpactReport.enterImprovement}
                            data-test-id="improvementTestId"
                            helperText={this.state.validationErrors.improvement && configJSON.ImpactReport.errorMessage}
                            error={this.state.validationErrors.improvement}
                        />
                    </Grid>
                    <Grid>{this.state.validationErrors.improvementLength && <ErrorMsg>{configJSON.ImpactReport.ErrorMessageForImprovement}</ErrorMsg>}</Grid>
                    <Grid xs={12} item>{this.renderNextButton()}</Grid>
                </Grid>
            </Box >
            <InfoModal open={this.state.reportSubmittedModal} info='Your valuable inputs have been submitted successfully!' closeModal={this.closeSuccessModalPir} modalType={configJSON.ModalTypes.Success} />
            <CommonSnackBar
                type="error"
                verticalPosition='top'
                horizontalPosition='center'
                open={this.state.snackBarIsOpen}
                onClose={() => this.onSnackbarClose()}
                message={this.state.snackBarMsgPir}
                duration={2000}
            />
        </>)
    }

    renderDeleteModal() {
        return (
            <InfoModal
                modalType={configJSON.ModalTypes.Confirm}
                open={this.state.deleteModal}
                closeModal={this.closeDeleteModalPir}
                info={configJSON.ImpactReport.ConfirmMsg}
                title=""
                confirmImage={deleteAlertIcon}
                submitBtnText={configJSON.ButtonTexts.Delete}
                submit={() => this.handleDeleteCallPir()}
            />
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return <>
            <SidebarLayout {...this.props} >
                <PageContentLayout
                    title={configJSON.ImpactReport.ProgramImpactReport}
                    handlePublishReport={this.state.currentPage === "pirList" ? this.handlePublishClickPir : undefined}
                    callingPage={configJSON.ImpactReport.CallingPage}
                    goBack={this.goBack}
                    btnTitle="Add"
                >
                    {this.state.currentPage !== "pirList" && this.renderPirForm()}
                    {this.state.currentPage === "pirList" && <ProgramImpactReportPage
                        data={this.state.reportData}
                        data-test-id='page-component'
                        pageNo={this.state.pageNo}
                        handlePageChange={(value: any) => this.handlePageChange(value)}
                        handleDeleteIconClick={(id: any) => this.handleDeleteIconClick(id)}
                        handleEditIconClick={(id: any) => this.handleEditIconClick(id)}
                    />}
                    {this.renderDeleteModal()}
                </PageContentLayout>
            </SidebarLayout>
        </>
        // Customizable Area End
    }
}

// Customizable Area Start
export const webStyle = {
    editIcon: {
        height: "1.25vw",
        width: "1.25vw",
        padding: "0 0.4vw",
        cursor: "pointer"
    },
    label: {
        color: '#3B3B3B',
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontWeight: 600
    },
    editWrapper: {
        padding: '32px 24px 24px 24px',
        // marginTop: "2.778vw"
    },
    themeImage: {
        height: "15.63vw",
        width: "20.486vw"
    },
    imageWrapper: {
        padding: "1.181vw",
        margin: "0.8vw",
        boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
        cursor: "pointer"
    },
    titleContainer: {
        marginTop: "1.042vw",

    },
    themeTitleWrapper: {
        width: "20.486vw",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    themeTitle: {
        fontSize: "1.389vw",
        margin: "unset",

    },
    nextButton: {
        display: 'block',
        padding: "0",
        background: "#0056A3",
        height: "48px",
        width: "175px",
        marginLeft: 'auto',
        fontSize: "16px",
        textTransform: "none" as "none",
        color: "fff",
        "&:hover": {
            background: "#010E36",
            opacity: 0.8
        }
    },

    textColor: {
        color: "#f44336",
        fontSize: "12px",
        marginTop: "0.2vw" 
    }
}
const ErrorMsg = styled(Box)({
    color: "red", 
    fontSize: "1vw", 
    marginTop: "0.2vw",
    marginLeft:"15px"
})
// Customizable Area End
