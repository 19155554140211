import React from 'react'
// Customizable Area Start
import SidebarLayout from "../../SidebarLayout.web";
import { Box, Grid, Typography, Button,styled } from "@material-ui/core";
import TimelyProgressReportController from './TimelyProgressReportController.web';
import { emptyStarRatingIcon, filledStarRatingIcon, addImpactStoryBtn, deleteAlertIcon, successfulMan } from "../../assets"
import "../ProgramImpactReport/ProgramImpactReport.css";
import { ProgramImpactReportPage, webStyle } from '../ProgramImpactReport/ProgramImpactReport.web';
import PageContentLayout from '../../PageContentLayout.web';
import CommonSelect from '../../CommonComponents/CommonSelect.web';
import CommonTextField from '../../CommonComponents/CommonTextField.web';
import InfoModal from '../../CommonComponents/InformationModal.web';
import CommonSnackBar from '../../CommonComponents/CommonSnackBar.web';
// Customizable Area End
const configJSON = require("../../config");

export default class TimelyProgressReport extends TimelyProgressReportController {
    // Customizable Area Start
    renderNextButtonTpr() {
        return (
            <Button
                data-test-id="selectNextBtnTpr"
                style={webStyle.nextButton}
                onClick={() => this.handleCreateClick()}
                className='nextButton'
                id="TprNextBtn"
            >
                {this.state.editMode ? "Update" : "Create"}
            </Button>
        )
    }

    renderErrorMessage = () => {
        return(
            <>
                {this.state.validationErrors.programTrackerLength && <BoxStyle> <Typography className='textColor'>{configJSON.TimelyProgress.CharacterError}</Typography> </BoxStyle>}
            </>
        )
    }

    renderErrorFeedback = (type:string) => {
        if(type === "student"){
            return (
            <> {this.renderStudentFeedback(this.state.validationErrors.studentFeedbackLength , this.state.validationErrors.studentsFeedback )&& <ErrorMsg >{configJSON.TimelyProgress.ErrorMessageForAllFeedback}</ErrorMsg>}</>
            )
        }
        if(type === "parent"){
            return(
                <>{this.renderStudentFeedback(this.state.validationErrors.parentFeedbackLength , this.state.validationErrors.parentsFeedback) && <ErrorMsg >{configJSON.TimelyProgress.ErrorMessageForAllFeedback}</ErrorMsg>}</>
            )
        }
        if(type === "educator"){
            return(
                <>{this.renderStudentFeedback(this.state.validationErrors.educatorFeedbackLength , this.state.validationErrors.educatorsFeedback) && <ErrorMsg >{configJSON.TimelyProgress.ErrorMessageForAllFeedback}</ErrorMsg>}</>
            )
        }
    }

    renderTprForm = () => {
        return (<>
            <Box sx={webStyle.editWrapper} >
                <Grid id="TprSelect" className='formContainer' spacing={2} container>
                    <Grid id="TprSelect" className='textInputContainer' xs={12} item>
                        <CommonSelect
                            label={`${configJSON.TimelyProgress.ProgramName}*`}
                            handleChange={(event: any) => this.handleInputChange(event, "course")}
                            menuList={this.state.courseList}
                            value={this.state.selectedCourse.name}
                            disabled={!!this.state.editMode}
                            placeholder={configJSON.TimelyProgress.EnterSchoolName}
                            error={this.state.validationErrors.selectedCourse}
                        />
                        {this.state.validationErrors.selectedCourse && <Typography id="TprSelect" style={{ color: "red", fontSize: "1vw", marginTop: "0.2vw" }}>* Please Select a Course!</Typography>}
                    </Grid>
                    <Grid id="TprSelect" style={{ paddingRight: "0.833vw" }} className='textInputContainer' xs={12} sm={6} item>
                        <CommonSelect
                            label={`${configJSON.TimelyProgress.SchoolName}*`}
                            placeholder={configJSON.TimelyProgress.EnterProgramName}
                            handleChange={(event: any) => this.handleInputChange(event, "school")}
                            value={this.state.selectedSchool.name}
                            menuList={this.state.schoolList}
                            disabled={!!this.state.editMode}
                            error={this.state.validationErrors.selectedSchool}
                        />
                        {this.state.validationErrors.selectedSchool && <Typography id="TprSelect" style={{ color: "red", fontSize: "1vw", marginTop: "0.2vw" }}>* Please Select a School!</Typography>}
                    </Grid>
                    <Grid id="TprSelect" className='textInputContainer' xs={12} sm={6} item>
                        <CommonSelect
                            handleChange={(event: React.ChangeEvent<HTMLSelectElement>) => this.handleInputChange(event, "year")}
                            menuList={this.state.academicYearList}
                            label={`${configJSON.TimelyProgress.AcademicYear}*`}
                            value={this.state.academic_year}
                            error={this.state.validationErrors.academicYear}
                            disabled={!!this.state.editMode}
                            placeholder={configJSON.TimelyProgress.EnterAcademicYear}
                        />
                        {this.state.validationErrors.academicYear 
                            && <BoxStyle> <Typography className='textColor'>* Please Select a academic year!</Typography> </BoxStyle>}
                    </Grid>
                    <Grid className='textInputContainer' xs={12} item>
                        <CommonTextField
                            multiline
                            minRows={10}
                            value={this.state.selectedCourse.overview}
                            handleChange={(event: any) => this.handleInputChange(event, "overview")}
                            mb={'0px'}
                            label={`${configJSON.TimelyProgress.Overview}*`}
                            name={configJSON.TimelyProgress.OverviewName}
                            placeholder={configJSON.TimelyProgress.Overview}
                            data-test-id="InputField"
                        />
                    </Grid>
                    <Grid className='textInputContainer' xs={12} item>
                        <CommonTextField
                            multiline
                            minRows={1}
                            value={this.state.programTrack}
                            handleChange={(event: any) => this.handleInputChange(event, "programTrack")}
                            mb={'0px'}
                            label={`${configJSON.TimelyProgress.ProgramTrack}*`}
                            name={configJSON.TimelyProgress.ProgramTrack}
                            placeholder={configJSON.TimelyProgress.EnterText}
                            data-test-id="programTrackerTestId"
                            helperText={this.state.validationErrors.academicYear && "* This field can't be left empty!"}
                            error={this.state.validationErrors.programTrack}
                        />
                    </Grid>
                    {this.renderErrorMessage()}
                    <Grid className='textInputContainer' xs={12} item>
                        <div className='subTitleContainer'>Attendance</div>
                    </Grid>
                    <Grid className='textInputContainer' xs={6} item>
                        <div className='subTitleContainer'>Grade</div>
                    </Grid>
                    <Grid className='textInputContainer' xs={6} item>
                        <div className='subTitleContainer'>Attendance %</div>
                    </Grid>
                    {this.state.selectedGradesAttendanceFirst.map((item: any) => {
                        return (<>
                            <Grid key={item.id} style={{ paddingRight: "0.833vw" }} className='textInputContainer' xs={6} item>
                                <CommonSelect
                                    placeholder={configJSON.TimelyProgress.SelectGrade}
                                    handleChange={(event: any) => this.handleAttendanceFirstChange(event, item.id, "attendanceFirstDropDown")}
                                    value={item.value}
                                    menuList={this.state.gradeList}
                                />
                            </Grid>
                            <Grid className='textInputContainer' xs={6} item>
                                <CommonTextField
                                    value={item.attendance}
                                    handleChange={(event: any) => this.handleAttendanceFirstChange(event, item.id, "attendanceFirstInput")}
                                    mb={'0px'}
                                    name={configJSON.TimelyProgress.Attendance}
                                    placeholder={configJSON.TimelyProgress.EnterValue}
                                    data-test-id="InputField"
                                />
                            </Grid>
                        </>
                        )
                    })}
                    <Grid xs={12} item>
                        {this.state.validationErrors.attendanceFirst && <Typography style={{ color: "red", fontSize: "1vw", marginTop: "0.2vw" }}>* This field can't be left empty!</Typography>}
                        <div style={{ marginBottom: '12px' }}><img data-test-id="addStoryBtn" onClick={() => this.handleAddNewAttendance("attendanceFirst")} className='addImpactStoryBtn' src={addImpactStoryBtn}></img></div>
                    </Grid>
                    <Grid className='textInputContainer' xs={12} item>
                        <div className='subTitleContainer'>Attendance</div>
                    </Grid>
                    <Grid style={{ paddingRight: "0.833vw" }} className='textInputContainer' xs={6} item>
                        <div className='subTitleContainer'>Grade</div>
                    </Grid>
                    <Grid className='textInputContainer' xs={6} item>
                        <div className='subTitleContainer'>Number of students</div>
                    </Grid>
                    {this.state.selectedGradesAttendanceSecond.map((item: any) => {
                        return (<>
                            <Grid key={item.id} style={{ paddingRight: "0.833vw" }} className='textInputContainer' xs={6} item>
                                <CommonSelect
                                    placeholder={configJSON.TimelyProgress.SelectGrade}
                                    handleChange={(event: any) => this.handleAttendanceSecondChange(event, item.id, "attendanceSecondDropDown")}
                                    value={item.value}
                                    menuList={this.state.gradeList}
                                />
                            </Grid>
                            <Grid className='textInputContainer' xs={6} item>
                                <CommonTextField
                                    value={item.attendance}
                                    handleChange={(event: any) => this.handleAttendanceSecondChange(event, item.id, "attendanceSecondInput")}
                                    mb={'0px'}
                                    name={configJSON.TimelyProgress.Attendance}
                                    placeholder={configJSON.TimelyProgress.EnterValue}
                                    data-test-id="InputField"
                                />
                            </Grid>
                        </>
                        )
                    })}
                    <Grid xs={12} item>
                        {this.state.validationErrors.attendanceSecond && <Typography style={{ color: "red", fontSize: "1vw", marginTop: "0.2vw" }}>* This field can't be left empty!</Typography>}
                        <div style={{ marginBottom: '12px' }}><img data-test-id="addStoryBtn" onClick={() => this.handleAddNewAttendance("attendanceSecond")} className='addImpactStoryBtn' src={addImpactStoryBtn}></img></div>
                    </Grid>
                    <Grid className='textInputContainer' style={{ margin: '10px 0' }} xs={12} item>
                        <div className='subTitleContainer'>Feedback : Students</div>
                    </Grid>
                    {this.state.studentsFeedback.map((message: any) => {
                        return (
                            <Grid key={message.id} className='textInputContainer' xs={12} item>
                                <CommonTextField
                                    multiline
                                    minRows={5}
                                    value={message.message}
                                    handleChange={(event: any) => this.handleEducatorMessageChange(event, message.id, "students")}
                                    mb={'0px'}
                                    name={configJSON.TimelyProgress.Message}
                                    placeholder={configJSON.TimelyProgress.EnterMessage}
                                    data-test-id="studentFeedbackTestId"
                                />
                            </Grid>
                        )
                    })}
                    {this.renderStudentFeedback(this.state.validationErrors.studentsFeedback , !this.state.validationErrors.studentFeedbackLength ) && <Typography style={{ color: "red", fontSize: "1vw", marginTop: "0.2vw" }}>* This field can't be left empty.</Typography>}
                    {this.renderErrorFeedback("student")}
                    {this.renderStudentFeedback(this.state.validationErrors.studentFeedbackLength , !this.state.validationErrors.studentsFeedback) && <ErrorMsg >{configJSON.TimelyProgress.ErrorMessageLength}</ErrorMsg>}
                    {this.state.validationErrors.studentFeedbackCharacter && <ErrorMsg>{configJSON.TimelyProgress.CharacterError}</ErrorMsg>}
                    <Grid xs={12} item><img data-test-id="addStoryBtn" onClick={() => this.handleAddFeedBack()} className='addImpactStoryBtn' src={addImpactStoryBtn}></img></Grid>
                    
                    <Grid className='textInputContainer' style={{ margin: '10px 0' }} xs={12} item>
                        <div className='subTitleContainer'>Feedback : Parents</div>
                    </Grid>

                    {this.state.parentsFeedback.map((message: any) => {
                        return (
                            <Grid key={message.id} className='textInputContainer' xs={12} item>
                                <CommonTextField
                                    multiline
                                    minRows={5}
                                    value={message.message}
                                    handleChange={(event: any) => this.handleEducatorMessageChange(event, message.id, "parents")}
                                    mb={'0px'}
                                    name={configJSON.TimelyProgress.Message}
                                    placeholder={configJSON.TimelyProgress.EnterMessage}
                                    data-test-id="parentFeedbackTestId"
                                />
                            </Grid>
                        )
                    })}           
                    {this.renderStudentFeedback(this.state.validationErrors.parentsFeedback , !this.state.validationErrors.parentFeedbackLength) && <Typography style={{ color: "red", fontSize: "1vw", marginTop: "0.2vw" }}>* This field can't be left empty.</Typography>}
                    {this.renderErrorFeedback("parent")}
                    {this.renderStudentFeedback(this.state.validationErrors.parentFeedbackLength , !this.state.validationErrors.parentsFeedback) && <ErrorMsg >{configJSON.TimelyProgress.ErrorMessageLength}</ErrorMsg>}
                    {this.state.validationErrors.parentFeedbackCharacter && <ErrorMsg>{configJSON.TimelyProgress.CharacterError}</ErrorMsg>}
                    <Grid xs={12} item><img data-test-id="addStoryBtn" onClick={() => this.handleAddFeedBackParent()} className='addImpactStoryBtn' src={addImpactStoryBtn}></img></Grid>

                    <Grid className='textInputContainer' style={{ margin: '10px 0' }} xs={12} item>
                        <div className='subTitleContainer'>Feedback : Educators</div>
                    </Grid>

                    {this.state.educatorsFeedback.map((message: any) => {
                        return (
                            <Grid key={message.id} className='textInputContainer' xs={12} item>
                                <CommonTextField
                                    multiline
                                    minRows={5}
                                    value={message.message}
                                    handleChange={(event: any) => this.handleEducatorMessageChange(event, message.id, "educators")}
                                    mb={'0px'}
                                    name={configJSON.TimelyProgress.Message}
                                    placeholder={configJSON.TimelyProgress.EnterMessage}
                                    data-test-id="educatorFeedbackTestId"
                                />
                            </Grid>
                        )
                    })}
                    {this.renderStudentFeedback(this.state.validationErrors.educatorsFeedback , !this.state.validationErrors.educatorFeedbackLength) && <Typography style={{ color: "red", fontSize: "1vw", marginTop: "0.2vw" }}>* This field can't be left empty!</Typography>}
                    {this.renderStudentFeedback(this.state.validationErrors.educatorFeedbackLength , !this.state.validationErrors.educatorsFeedback) && <ErrorMsg >{configJSON.TimelyProgress.ErrorMessageLength}</ErrorMsg>}
                    {this.renderErrorFeedback("educator")}
                    {this.state.validationErrors.educatorFeedbackCharacter && <ErrorMsg>{configJSON.TimelyProgress.CharacterError}</ErrorMsg>}
                    <Grid xs={12} item><img data-test-id="addStoryBtn" onClick={() =>this.handleAddFeedBackEducator()} className='addImpactStoryBtn' src={addImpactStoryBtn}></img></Grid>
                    <Grid className='textInputContainer' xs={12} item style={{ margin: '20px 0' }}>
                        <Box>
                            <Grid spacing={2} className={'tableWrapper'} container>
                                <Grid className='header' xs={12} item>
                                    <Box className='subTitleContainer' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                                        <Box> Behaviour</Box>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '267px' }}>
                                            <Box>Low</Box>
                                            <Box>Moderate</Box>
                                            <Box>High</Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                {this.state.ratings.map((skill: any) => {
                                    if (skill !== undefined) {
                                        return <Grid className='row' key={skill.name} xs={12} item>
                                            <Box className="skillsFormBody">
                                                <Box className="behaviourRatingContainer">
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        {<img style={{ width: "30px", height: "30px", marginRight: "20px" }} src={skill.icon}></img>}
                                                        <Typography className="skillNameText" component="span">
                                                            {skill.name}
                                                        </Typography>
                                                    </div>
                                                    <Box>
                                                        {this.renderRatingTpr(this.handleSkil1RatingChange, skill.name)}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    }
                                })}
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid style={{ marginTop: "1.67vw" }} className='textInputContainer' xs={12} item>
                        {this.renderNextButtonTpr()}
                    </Grid>
                </Grid>
            </Box >
            <InfoModal
                open={this.state.reportSubmittedModal}
                closeModal={this.closeSuccessModal}
                info={configJSON.TimelyProgress.SuccessfulMsg}
                successImage={successfulMan}
                modalType='success'
            />
            <CommonSnackBar
                type="error"
                verticalPosition='top'
                horizontalPosition='center'
                open={this.state.snackBarIsOpen}
                onClose={() => this.onSnackbarClose()}
                message={this.state.snackBarMsgTpr}
                duration={4000}
            />
        </>)
    }

    renderRatingTpr = (onChange: any, skillName: string) => {
        const valueTpr = this.state.ratings.find((item: any) => {
            return item?.name === skillName;
        })?.rating;
        const handleRatingChangeTpr = (newValue: any) => {
            onChange(newValue, skillName);
        };

        return (
            <Box id="ratingTpr" sx={{ display: 'flex', alignItems: 'center', width: '290px', justifyContent: 'space-evenly' }}>
                {Array.from({ length: 5 }, (_, index) => (
                    <img
                        key={index}
                        src={index < valueTpr ? filledStarRatingIcon : emptyStarRatingIcon}
                        alt={index < valueTpr ? 'Filled Star' : 'Empty Star'}
                        onClick={() => handleRatingChangeTpr(index + 1)}
                        className='ratingIcon'
                    />
                ))}
            </Box>
        );
    };

    renderDeleteModal() {
        return (
            <InfoModal
                modalType={configJSON.ModalTypes.Confirm}
                open={this.state.deleteModal}
                closeModal={this.closeDeleteModal}
                title=""
                submitBtnText={configJSON.ButtonTexts.Delete}
                submit={() => this.handleDeleteCall()}
                info={configJSON.TimelyProgress.ConfirmMsg}
                confirmImage={deleteAlertIcon}
            />
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return <>
            <SidebarLayout {...this.props} >
                <PageContentLayout
                    title={configJSON.TimelyProgress.TimelyProgressReport}
                    handlePublishReport={this.state.currentPage === "TprList" ? this.handleCreateTprClick : undefined}
                    callingPage={configJSON.TimelyProgress.CallingPage}
                    goBack={this.goBack}
                    id="tprCommonHeader"
                    btnTitle="Add"
                >
                    {this.state.currentPage !== "TprList" && this.renderTprForm()}
                    {this.state.currentPage === "TprList" && <ProgramImpactReportPage

                        data={this.state.reportData}
                        data-test-id='page-component'
                        pageNo={this.state.pageNo}
                        handlePageChange={(value: any) => this.handleTprPageChange(value)}
                        handleDeleteIconClick={(id: any) => this.handleTprDeleteIconClick(id)}
                        handleEditIconClick={(id: any) => this.handleTprEditIconClick(id)}
                    />}
                    {this.renderDeleteModal()}
                </PageContentLayout>
            </SidebarLayout>
        </>
        // Customizable Area End
    }
}
// Customizable Area Start
const BoxStyle = styled(Box)({
    "& .textColor": {
        color: "#f44336",
        fontSize: "12px",
        marginTop: "0.2vw" 
    },
});

const ErrorMsg = styled(Box)({
    color: "red", 
    fontSize: "1vw", 
    marginTop: "0.2vw"
})
// Customizable Area End
