export const userProfile = require("../assets/userProfile.png");
export const facebook = require("../assets/facebook.svg");
export const instagram = require("../assets/instagram.svg");
export const linkedin = require("../assets/linkedin.svg");
export const twitter = require("../assets/twitter.svg");
export const youtube = require("../assets/youtube.svg");
export const logo = require("../assets/mainlogo.png");
export const hapchiLogo = require("../assets/hapchiMainLogo.svg");
export const right = require("../assets/right.png");
export const left = require("../assets/left.png");
export const footerlogo = require("../assets/hapchiFooterLogo.png"); 
export const map = require("../assets/map.png");
export const footerBanner = require("../assets/view_Bg.png");
export const tw = require("../assets/tw.svg");
export const fb = require("../assets/fb.svg");
export const ig = require("../assets/ig.svg");
export const youtubeFooter = require("../assets/youtubeFooter.svg");
export const linkdin = require("../assets/linkdin.svg");
export const message = require("../assets/message.svg");
export const call = require("../assets/call.svg");
export const curve = require("../assets/curve.svg");
export const bannerBg = require("../assets/banner_bg.svg");
export const rightArrow = require("../assets/rightArrowIcon.svg");
export const leftTop = require("../assets/left_top.svg");
export const rightBottom = require("../assets/right_bottom.svg");
export const subMenuArrow = require("../assets/submenu_arrow.svg");
export const bottomLeftShape = require("../assets/bottom_left.svg");
export const pinIcon = require("../assets/pin.png")
