import { IBlock } from "../../../../../framework/src/IBlock";

import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import RootContext from "../../../../../components/src/RootContext";


let config = require("../../../../../framework/src/config");
// Customizable Area Start
// Customizable Area End

export const configJSON = require("../../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export interface StudentAnsChoice {
  id: number;
  content: string;
  is_correct?: boolean;
}

export interface BucketAttributes {
  id: number;
  question_id: number;
  content: string;
  is_correct: boolean;
}

export interface SelectedChoice {
  id: number;
  content: string;
}

export interface StudentSelectedBucket {
  data: {
      id: string;
      type: string;
      attributes: BucketAttributes;
  };
  selected_choices: SelectedChoice[];
  // is_correct?: boolean;
}

export interface StudentAnswerBucket {
  choices: number[];
  id: string | number;
  type: string;
  attributes: BucketAttributes;
}
export interface StudentQuestionBucket {
  choices: SelectedChoice[];
  id: string | number;
  type: string;
  attributes: BucketAttributes;
}

export interface DragQuestionData {
  question_id: number;
  text: string;
  question_type: string;
  document_file: string | null;
  answer_document_file: string | null;
  student_selected_choice_id: {
      buckets: StudentSelectedBucket[],
  };
  choices: StudentAnsChoice[]
}

export interface ObjectiveQuestion {
  question_id: number;
  id: string | number;
  text: string;
  question_type: "objective" | "true_false" | "puzzle" | "drag_and_drop";
  student_selected_choice_id: number;
  choices: Choice[];
  document_file: any;
  answer_document_file: any;
}

export interface Choice {
  id: number;
  content: string;
  is_correct: boolean;
}

export interface TextBasedQuestion {
  id: any;
  text: string;
  question_type: 'text_based';
  student_answered: string;
  solution: string;
  document_file: any;
  answer_document_file: any;
}

export type Questions = ObjectiveQuestion | TextBasedQuestion;

export interface MpowerYouMatterChecksPageProps {
  states: S;
  isYouMatter: boolean;
  isUpdateCheck: boolean;
  openModal: (modalType: string) => void;
  handleTab: (_event: React.ChangeEvent<{}>, newValue: number) => void;
  handleChange: (event: React.ChangeEvent<{ value: any, name: any }>) => void;
  handleClear: (name: any, type: any) => void;
}
const addFormDataDefault = {
  title: '',
  you_matter_details: '',
  courseName: '',
  sessionName: '',
  schoolName: '',
  sectionName: '',
  startDate: new Date(),
  dueDate: new Date(new Date().setDate(new Date().getDate() + 1)).setHours(0, 0, 0, 0),
  scheduledTime: new Date(),
  difficulty: '',
  totalSectionQuestions: 0,
  duration: '',
  activateAfter: '',
  totalQuestionsInQuestionBank: 0,
  fields: [{ id: 1, sectionName: "", totalQuestions: "", questions: [], errors: { questions: "", section: "" } }]
}

export interface Fields {
  id: number;
  sectionName: string;
  totalQuestions: number | string;
  questions: QuestionsType[];
  errors: ErrorType;
}

interface ErrorType {
  questions: string;
  section: string;
}

export interface QuestionsType {
  id: number;
}

export interface AddFormData {
  title: string;
  you_matter_details: string;
  courseName: string;
  sessionName: string;
  schoolName: string;
  sectionName: string;
  difficulty: string;
  totalSectionQuestions: number;
  totalQuestionsInQuestionBank: number;
  fields: Fields[];
  activateAfter: string;
  duration: string;
}

interface Badge {
  id: number;
  title: string;
  body: string;
}

interface StudentData {
  student_name: string;
  school_name: string;
  grade: string;
  score: number;
  badge: Badge | null;
}

interface TimeClockErrors {
  activateAfter: string;
  duration: string;
}
export interface BucketData {
  id: string;
  type: string | 'bucket';
  attributes: {
      id: string,
      question_id: number,
      content: string | "Bucket" | null
      is_correct: boolean
  }
}
export interface Bucket {
  data: BucketData[]
}
// Customizable Area End
export interface S {
  // Customizable Area Start
  questionBankQueType: "objective" | "text_based" | "true_false" | "puzzle" | "drag_and_drop";
  // Customizable Area End
  isLoading: boolean;
  openModal: boolean;
  modalType: "success" | "warning" | "confirm" | "schedule";
  modalInfo: string;
  dashboardData: any;
  addMpowerYouMatter: AddFormData;
  errors: any;
  guideLines: any;
  filterCourseList: any;
  filterSectionList: any;
  filterSchoolList: any;
  addFormCourseList: any;
  addFormSessionList: any;
  addFormSchoolList: any;
  addFormSectionList: any;
  schoolFilter: any;
  sectionFilter: any;
  courseFilter: any;
  selectedGuidelines: any;
  isContextRestored: boolean;
  questionBankQuestions: any;
  extraCheckedQuestionId: any;
  checksPageData: any;
  page: number;
  questionBankQuestionType: "objective" | "text_based" | "true_false" | "puzzle";
  tab: number;
  badgeId: any;
  badgeDropdownList: any;
  score: number;
  totalPages: number;
  badgesList: any;
  totalScore: any;
  studentData: StudentData;
  timeClockErrors: TimeClockErrors;
}
interface SS { 
  // Customizable Area Start
  // Customizable Area End
}

export default class TrainerMpowerYouMatterController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  static contextType = RootContext;
  mpowerDashboardApiCallId: string = "";
  filterDropdownApiCallId: string = ""
  guidelinesApiCallId: string = "";
  addFormDropdownApiCallId: string = "";
  getquestionApiCallId: string = "";
  getTotalQuestionApiCallId: string = ""
  getBagesApiCallId: string = "";
  getQuestionAnswerApiCallId: string = "";
  submitMpowerYouMatterApiCallId: string = "";
  updateScoreApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      isLoading: false,
      openModal: false,
      modalType: 'success',
      modalInfo: '',
      dashboardData: [],
      guideLines: [],
      addMpowerYouMatter: addFormDataDefault,
      errors: {},
      filterCourseList: [],
      filterSectionList: [],
      filterSchoolList: [],
      addFormCourseList: [],
      addFormSessionList: [],
      addFormSchoolList: [],
      addFormSectionList: [],
      sectionFilter: "",
      schoolFilter: "",
      courseFilter: "",
      selectedGuidelines: [],
      isContextRestored: false,
      questionBankQuestions: [],
      extraCheckedQuestionId: -1,
      checksPageData: [],
      page: 1,
      questionBankQuestionType: 'objective',
      questionBankQueType: 'objective',
      tab: 0,
      badgeId: "",
      badgeDropdownList: [],
      score: 0,
      totalPages: 1,
      badgesList: [],
      studentData: {
        "student_name": "",
        "school_name": "",
        "grade": "",
        "score": 0,
        "badge": null
      },
      timeClockErrors: { activateAfter: "", duration: "" },
      totalScore: '0.0'
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          this.handleApiResponses(apiRequestCallId, responseJson)
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
      if (errorReponse) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }


  handleApiResponses = (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.mpowerDashboardApiCallId:
        this.setState({ dashboardData: responseJson.data, totalPages: responseJson?.page_options?.total_pages });
        break;
      case this.filterDropdownApiCallId:
        this.setFilterDropdownList(responseJson);
        break;
      case this.guidelinesApiCallId:
        this.setState({ guideLines: responseJson.data });
        break;
      case this.addFormDropdownApiCallId:
        this.setAddFormDropdownList(responseJson)
        break;
      case this.getquestionApiCallId:
        this.setState({ questionBankQuestions: responseJson.data });
        break;
      case this.getTotalQuestionApiCallId:
        if (responseJson.total_questions === 0) {
          this.handleOpenModal(configJSON.ModalTypes.Warning);
        }
        this.setState({ addMpowerYouMatter: { ...this.state.addMpowerYouMatter, totalQuestionsInQuestionBank: responseJson.total_questions, totalSectionQuestions: responseJson.total_questions } })
        break;
      case this.getBagesApiCallId:
        this.setState({ badgesList: responseJson.data })
        break;
      case this.getQuestionAnswerApiCallId:
        this.setState({ checksPageData: responseJson.data, studentData: responseJson.student, totalScore: Number(responseJson.total_scores), badgeId: responseJson.student?.badge?.id, score: Number(responseJson.student?.score) })
        break;
      case this.submitMpowerYouMatterApiCallId:
        this.context.setGlobal('formData', { readyToSchedule: false });
        this.handleOpenModal(configJSON.ModalTypes.Success)
        break;
      case this.updateScoreApiCallId:
        this.handleOpenModal(configJSON.ModalTypes.Success)
        break;
    }
  }

  setFilterDropdownList = (responseJson: any) => {
    const data = responseJson.data;
    const length = Object.keys(data).length;
    if (length === 3) {
      this.setState({ filterCourseList: data.mind_mastery_courses, filterSchoolList: data.schools })
    } else if (length === 2) {
      if (data.hasOwnProperty('schools')) {
        this.setState({ filterSchoolList: data.schools })
      } else {
        this.setState({ filterSectionList: data.grades })
      }
    } else if (length === 1) {
      this.setState({ filterSectionList: data.grades })
    }
  }

  setAddFormDropdownList = (responseJson: any) => {
    const data = responseJson.data;
    const length = Object.keys(data).length;
    if (length === 4) {
      this.setState({ addFormCourseList: data.mind_mastery_courses })
    }
    if (length === 3) {
      this.setState({ addFormSchoolList: data.schools, addFormSessionList: data.course_sessions })
    }
    if (length === 2) {
      this.setState({ addFormSectionList: data.grades })
    }
  }

  goTo = (module: string, params: Object = {}) => {
    if (this.props.navigation) {
      this.props.navigation.navigate(module, { ...params });
    }
  };

  goBack = () => {
    this.props.navigation.goBack();
  };

  getToken = () => {
    return localStorage.getItem("user_token");
  };

  handleGuidelineClick = (event: any) => {
    const id = Number(event?.target?.value)
    const isChecked = event?.target?.checked;
    if (isChecked) {
      const tempArr = this.state.selectedGuidelines;
      tempArr.push({ id: id })
      this.setState({ selectedGuidelines: tempArr })
    } else {
      const updatedArr = this.state.selectedGuidelines.filter((e: any) => e.id !== id)
      this.setState({ selectedGuidelines: updatedArr })
    }
  }

  handleGuidelinesNextClick = (context: any) => {
    const formData = { readyToSchedule: false, guidelines: this.state.selectedGuidelines }
    context.setGlobal('formData', formData)
    this.goTo(configJSON.TrainerMPowerYouMatter.CreateMPower)
  }

  handleQuestionListNext = (context: any, isYouMatter: boolean) => {
    context.setGlobal('formData', { ...context?.formData, readyToSchedule: true })
    this.goTo(isYouMatter ? configJSON.TrainerMPowerYouMatter.CreateYouMatter : configJSON.TrainerMPowerYouMatter.CreateMPower)
  }

  handleOpenModal = (modalType: any) => {
    this.setState({ openModal: true, modalType: modalType })
  }

  handleClose = () => {
    this.setState({ openModal: false, modalInfo: "", modalType: "success" })
  }

  handleOnChangeFormField = (event: React.ChangeEvent<{ value: any, name: any }>) => {
    const { name, value } = event.target;
    if (name === configJSON.TrainerMPowerYouMatter.ActivateAfter || name === configJSON.TrainerMPowerYouMatter.Duration) {
      this.handleTimeClockValues(name, value);
      this.setState({ isContextRestored: false, addMpowerYouMatter: { ...this.state.addMpowerYouMatter, [name]: value } })
    } else if (name === configJSON.TrainerMPowerYouMatter.SectionName) {
      const course = this.state.addMpowerYouMatter.courseName;
      const school = this.state.addMpowerYouMatter.schoolName;
      this.setState({ isContextRestored: false, addMpowerYouMatter: { ...this.state.addMpowerYouMatter, [name]: value } }, () => this.getTotalQuestions(course, school, this.state.addMpowerYouMatter.sectionName))
    } else {
      this.setState({ isContextRestored: false, addMpowerYouMatter: { ...this.state.addMpowerYouMatter, [name]: value } })
    }
  }

  handleTimeClockValues = (name: string, value: any) => {
    if (!(configJSON.TrainerMPowerYouMatter.OnlyValidNumbersRegex).test(value)) {
      this.setState({ isContextRestored: false, timeClockErrors: { ...this.state.timeClockErrors, [name]: configJSON.TrainerMPowerYouMatter.InvalidDays }, addMpowerYouMatter: { ...this.state.addMpowerYouMatter, [name]: value } })
    } else if (Number(value) > 365) {
      this.setState({ isContextRestored: false, timeClockErrors: { ...this.state.timeClockErrors, [name]: configJSON.TrainerMPowerYouMatter.DaysLimit }, addMpowerYouMatter: { ...this.state.addMpowerYouMatter, [name]: value } })
    } else {
      this.setState({ isContextRestored: false, timeClockErrors: { ...this.state.timeClockErrors, [name]: `` }, addMpowerYouMatter: { ...this.state.addMpowerYouMatter, [name]: value } })
    }
  }

  handleAddFields = (remaining: number) => {
    this.setState({
      addMpowerYouMatter: {
        ...this.state.addMpowerYouMatter,
        totalSectionQuestions: remaining,
        fields: [...this.state.addMpowerYouMatter.fields,
        {
          id: this.state.addMpowerYouMatter.fields.length + 1,
          sectionName: "",
          totalQuestions: "",
          questions: [],
          errors: { section: "", questions: "" }
        }]
      }
    })
  }

  handleRemoveField = (fieldIdToRemove: number) => {
    this.setState((prevState) => {
      const totalAddedQuestion = prevState.addMpowerYouMatter.fields.reduce((sum: any, field: any) => sum + Number(field.totalQuestions), 0);
      return {
        addMpowerYouMatter: {
          ...prevState.addMpowerYouMatter,
          totalSectionQuestions: Math.abs(prevState.addMpowerYouMatter.totalQuestionsInQuestionBank - totalAddedQuestion),
          fields: prevState.addMpowerYouMatter.fields.filter(
            (field) => field.id !== fieldIdToRemove
          ),
        },
      }
    });
  };

  removeQuestionFromSection = (sectionId: any, questionId: any, context: any) => {
    const fields = context?.formData?.addFormData?.fields;
    const fieldIndex = fields.findIndex((element: any) => element.id === sectionId)
    const currentSection = fields.filter((element: any) => element.id === sectionId)
    let questionArr = currentSection && currentSection[0].questions;
    questionArr = questionArr.filter((element: any) => element.id !== questionId)
    const updatedObject = {
      ...context.formData,
      addFormData: {
        ...context?.formData?.addFormData,
        fields: context?.formData?.addFormData?.fields?.map((element: any, index: number) => {
          if (index === fieldIndex) {
            return { ...element, questions: questionArr }
          }
          return element;
        })
      }
    }
    context.setGlobal('formData', updatedObject)
  }

  closeWarningModal = (sectionId: any, context: any) => {
    this.removeQuestionFromSection(sectionId, this.state.extraCheckedQuestionId, context)
    this.handleClose();
  }

  handleQuestionCheck = (event: any, content: string, type: string, sectionId: any, totalQuestions: any, context: any) => {
    event.stopPropagation();
    const fields = context?.formData?.addFormData?.fields;
    const id = Number(event?.target?.value)
    const isChecked = event?.target?.checked;
    const fieldIndex = fields.findIndex((element: any) => element.id === sectionId)
    const currentSection = fields.filter((element: any) => element.id === sectionId)
    let questionArr = currentSection && currentSection[0].questions;
    if (isChecked) {
      if (questionArr.length === totalQuestions) {
        this.setState({ extraCheckedQuestionId: id });
        this.handleOpenModal(configJSON.ModalTypes.Warning)
        return;
      }
      questionArr.push({ id: id, content: content, type: type });
    } else {
      questionArr = questionArr.filter((element: any) => element.id != id)
    }
    const updatedObject = {
      ...context.formData,
      addFormData: {
        ...context?.formData?.addFormData,
        fields: context?.formData?.addFormData?.fields?.map((element: any, index: number) => {
          if (index === fieldIndex) {
            return { ...element, questions: questionArr }
          }
          return element;
        })
      }
    }
    context.setGlobal('formData', updatedObject)
  }

  handleNoOfSections = (event: any, name: string, id: number) => {
    const value = event.target.value;
    const fieldsArr = this.state.addMpowerYouMatter.fields;
    const fieldIndex = fieldsArr.findIndex((element: any) => element.id === id)
    let msg = "";
    const grandTotal = this.state.addMpowerYouMatter.totalSectionQuestions;
    const limit = this.state.addMpowerYouMatter.totalQuestionsInQuestionBank;
    if (name === configJSON.TrainerMPowerYouMatter.Section) {
      msg = this.handleTextFieldError(name, value) as any
      this.updateField(fieldIndex, { sectionName: value, questions: [], errors: { ...fieldsArr[fieldIndex].errors, section: msg } });
    } else {
      const totalAddedQuestion = this.state.addMpowerYouMatter.fields.reduce((sum, field) => {
        if (field.id !== id) {
          return sum + Number(field.totalQuestions);
        }
        return sum;
      }, Number(value));
      const updateSectionQuestions = (grandTotal - Number(value)) < 0 ? 0 : (grandTotal - Number(value));
      if (limit - (totalAddedQuestion - value) == 0) {
        this.setState({ modalType: 'warning', openModal: true, modalInfo: `You have used all ${limit} questions in other sections please do some adjustments to add questions in the current field` })
      }
      if (value > limit - (totalAddedQuestion - value)) {
        msg = `${configJSON.TrainerMPowerYouMatter.NoOfQuestionLessThan} ${limit - (totalAddedQuestion - value) + 1}`
      }

      if (!(/^[1-9]\d*$/).test(value)) {
        msg = configJSON.TrainerMPowerYouMatter.InvalidTotalQuestion
      }
      this.updateField(fieldIndex, { totalQuestions: value, questions: [], errors: { ...fieldsArr[fieldIndex].errors, questions: msg } }, updateSectionQuestions);
    }
  }

  updateField = (fieldIndex: number, updatedValues: any, updateSectionQuestions?: any) => {
    this.setState(prevState => {
      const updatedFields = prevState.addMpowerYouMatter.fields.map((field: any, index: number) => {
        if (index === fieldIndex) {
          return { ...field, ...updatedValues };
        }
        return field;
      });

      return {
        addMpowerYouMatter: {
          ...prevState.addMpowerYouMatter,
          fields: updatedFields,
          totalSectionQuestions: updateSectionQuestions || prevState.addMpowerYouMatter.totalSectionQuestions
        }
      };
    });
  }

  handleFormNext = (isYouMatter: boolean, context: any) => {
    const formData = { ...context.formData, addFormCourseList: this.state.addFormCourseList, addFormSessionList: this.state.addFormSessionList, addFormSchoolList: this.state.addFormSchoolList, addFormSectionList: this.state.addFormSectionList, addFormData: this.state.addMpowerYouMatter }
    context.setGlobal('formData', formData)
    this.goTo(isYouMatter ? configJSON.TrainerMPowerYouMatter.TrainerYouMatterQuestionList : configJSON.TrainerMPowerYouMatter.TrainerMpowerQuestionList)
  }

  handleTextFieldError = (name: string, value: any) => {
    const regxForText = /^[^\D].*/;
    if (!value) {
      return `${name} is required!`;
    } else if (regxForText.test(value)) {
      return `${name} is not valid!`;
    } else {
      return ""
    }
  }

  handleDropdownChange = (event: React.ChangeEvent<{ value: any, name: any }>) => {
    const { name, value } = event.target;
    this.setState({ ...this.state, [name]: value })
  }

  handleClearSelect = (name: any, type: any) => {
    if (type === 'form') {
      if (name === configJSON.TrainerMPowerYouMatter.SchoolName) {
        this.setState({ isContextRestored: false, addFormSectionList: [], addMpowerYouMatter: { ...this.state.addMpowerYouMatter, schoolName: "", sectionName: "", } });
      } else {
        this.setState({ isContextRestored: false, addMpowerYouMatter: { ...this.state.addMpowerYouMatter, [name]: "" } });
      }
    } else {
      this.setState({ ...this.state, [name]: "" })
    }
  }

  handlePage = (page: number) => {
    this.setState({ page: page })
  }

  handleQuestionBankTabChange = (value: number) => {
    switch (value) {
      case 0: this.setState({ questionBankQueType: 'objective' }); break;
      case 1: this.setState({ questionBankQueType: 'text_based' }); break;
      case 2: this.setState({ questionBankQueType: 'true_false' }); break;
      case 3: this.setState({ questionBankQueType: 'puzzle' }); break;
      case 4: this.setState({ questionBankQueType: 'drag_and_drop' }); break;
      default: this.setState({ questionBankQueType: 'objective' });
    }
  };

  handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    this.handleQuestionBankTabChange(newValue)
    this.setState({ tab: newValue })
  };

  handleUnload = () => {
    localStorage.setItem('isReload', `${true}`)
  };

  unloadCallback = (e: any) => {
    e.preventDefault();
    if (e) {
      e.returnValue = '';
    }
    return '';
  };

  getMpowerDashboard(course: any, school: any, section: any, page: any, isYouMatter: boolean, mpowerYouMatterId: any, schoolInfo: any) {
    this.setState({ dashboardData: [] });
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const isCourseFilterStarted = course !== ""
    const isSchoolFilterStarted = school !== ""
    const isSectionFilterStarted = section !== ""

    if (isSectionFilterStarted && !isSchoolFilterStarted) {
      this.setState({ sectionFilter: "", filterSectionList: [] })
      return;
    }

    let preFix = isYouMatter ? `you_matters?page=${page}` : `mpower_checks?page=${page}`;

    let endPoint = preFix;
    if (isCourseFilterStarted) {
      endPoint = `${preFix}&mind_mastery_course_id=${course}`;
      if (isSchoolFilterStarted) {
        endPoint = `${preFix}&mind_mastery_course_id=${course}&school_id=${school}`
      }
      if (isSectionFilterStarted) {
        endPoint = `${preFix}&mind_mastery_course_id=${course}&grade_id=${section}&school_id=${school}`
      }
    } else if (isSchoolFilterStarted) {
      endPoint = `${preFix}&school_id=${school}`
      if (isSectionFilterStarted) {
        endPoint = `${preFix}&grade_id=${section}&school_id=${school}`
      }
    }

    if (mpowerYouMatterId) {
      let filterEntity = `mpower_check_id=${mpowerYouMatterId}`
      if (isYouMatter) {
        filterEntity = `you_matter_id=${mpowerYouMatterId}`
      }
      endPoint = `student_with_filter?${filterEntity}&school_name=${schoolInfo.schoolName}&grade_name=${schoolInfo.gradeName}&section_name=${schoolInfo.sectionName}&page=${page}`;
    }

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.mpowerDashboardApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      endPoint,
      header
    );
  }

  getFilterDropdownData(prevCourse: any, course: any, school: any) {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const isCourseFilterStarted = course !== prevCourse;
    const isSchoolFilterStarted = school !== "";
    if (!isCourseFilterStarted && typeof course === 'number' && typeof school === 'string') {
      // don't call api if course is not change and school has string default value
      // this state will be true when we come back on addfrom page to edit some dropdown data
      // upon hitting course change school state will change and componentupdate will hit this api
      return;
    }
    let endPoint = configJSON.TrainerMPowerYouMatter.FilterResoures
    if (isCourseFilterStarted) {
      endPoint = `${configJSON.TrainerMPowerYouMatter.FilterResoures}?mind_mastery_course_id=${course}`
      this.setState({ schoolFilter: "", sectionFilter: "", filterSectionList: [], filterSchoolList: [] })
    } else if (isSchoolFilterStarted) {
      endPoint = `${configJSON.TrainerMPowerYouMatter.FilterResoures}?mind_mastery_course_id=${course}&school_id=${school}`
      this.setState({ sectionFilter: "", filterSectionList: [] })
      if (course === "") {
        endPoint = `${configJSON.TrainerMPowerYouMatter.FilterResoures}?school_id=${school}`;
      }
    }
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.filterDropdownApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      endPoint,
      header
    );
  }

  getGuideLines() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.guidelinesApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.TrainerMPowerYouMatter.Guidelines,
      header
    );
  }

  getAddFormDropdownData(prevCourse: any, course: any, school: any) {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    let endPoint = configJSON.TrainerMPowerYouMatter.CreateResources;
    const isCourseDropdownTriggered = course !== prevCourse;
    const isSchoolDropdownTriggered = school !== ''
    if (!isCourseDropdownTriggered && typeof course === 'number' && typeof school === 'string') {
      // don't call api if course is not change and school has string default value
      // this state will be true when we come back on addfrom page to edit some dropdown data
      // upon hitting course change school state will change and componentupdate will hit this api
      return;
    }
    if (isCourseDropdownTriggered) {
      endPoint = `${configJSON.TrainerMPowerYouMatter.CreateResources}?mind_mastery_course_id=${course}`
      this.setState({ addFormSchoolList: [], addFormSessionList: [], addFormSectionList: [], addMpowerYouMatter: { ...this.state.addMpowerYouMatter, schoolName: '', sectionName: '', sessionName: '' } })
    } else if (isSchoolDropdownTriggered) {
      this.setState({ addFormSectionList: [], addMpowerYouMatter: { ...this.state.addMpowerYouMatter, sectionName: '' } })
      endPoint = `${configJSON.TrainerMPowerYouMatter.CreateResources}?mind_mastery_course_id=${course}&school_id=${school}`
    }
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addFormDropdownApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      endPoint,
      header
    );
  }

  getQuestions(questionType: string, formData: any) {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getquestionApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      `${configJSON.TrainerMPowerYouMatter.QuestionBanksEndPoint}?school_id=${formData?.addFormData?.schoolName}&mind_mastery_course_id=${formData?.addFormData?.courseName}&grade_id=${formData?.addFormData?.sectionName}&question_type=${questionType}`,
      header
    );
  }

  getTotalQuestions(course: any, school: any, section: any) {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getTotalQuestionApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      `${configJSON.TrainerMPowerYouMatter.QuestionBanksEndPoint}?school_id=${school}&mind_mastery_course_id=${course}&grade_id=${section}&for_validation_flag=true`,
      header
    );
  }

  getBadgesList() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getBagesApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.TrainerMPowerYouMatter.BadgesEndPoint,
      header
    );
  }

  getStudentResponse(id: any, studentId: any, isYouMatter: boolean) {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    let endPoint = `${configJSON.TrainerMPowerYouMatter.QuestionAnswer}?student_id=${studentId}&mpower_check_id=${id}`;
    if (isYouMatter) {
      endPoint = `${configJSON.TrainerMPowerYouMatter.QuestionAnswer}?student_id=${studentId}&you_matter_id=${id}`
    }

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getQuestionAnswerApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      endPoint,
      header
    );
  }

  handleSubmitYouMatterMpower = (context: any) => {
    this.handleClose();
    const addFormData: AddFormData = context?.formData?.addFormData;

    let defaultAns: any = {
      title: addFormData?.title,
      you_matter_details: addFormData?.you_matter_details,
      mind_mastery_course_id: addFormData.courseName,
      course_session_id: addFormData.sessionName,
      school_id: addFormData.schoolName,
      grade_id: addFormData.sectionName,
      active_after: addFormData.activateAfter,
      end_after: addFormData.duration
    };

    const guidelines = context?.formData?.guidelines;

    const sections = addFormData.fields?.map(({ sectionName, totalQuestions, questions }) => ({
      name: sectionName,
      total_questions: Number(totalQuestions),
      questions: questions.map(({ id }) => ({ id }))
    }));
    let endPoint = `mpower_checks`
    let ans = {}
    if (guidelines?.length > 0) {
      ans = { mpower_check: { ...defaultAns, difficulty_level: addFormData.difficulty }, guidelines, sections }
    } else {
      endPoint = 'you_matters'
      ans = { you_matter: defaultAns, sections }
    }

    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.submitMpowerYouMatterApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardPostApiMethod,
      endPoint,
      header,
      JSON.stringify(ans)
    );
  }

  submitEvaluation(id: any, studentId: any, isYouMatter: boolean) {
    this.handleClose();
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };

    let ans: any = {
      mpower_check_id: Number(id),
      student_id: Number(studentId),
      updated_scores: Number(this.state.score),
      badge_id: Number(this.state.badgeId)
    }

    if (isYouMatter) {
      ans = { you_matter_id: Number(id), student_id: Number(studentId), updated_scores: Number(this.state.score) }
    }

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateScoreApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardPatchApiMethod,
      configJSON.TrainerMPowerYouMatter.UpdateResource,
      header,
      JSON.stringify(ans)
    );
  }

  async makeApiCall(
    uniqueApiCallId: string,
    method: string,
    endpoint: string,
    headers: any,
    body?: any
  ) {
    // Customizable Area Start
    let fullURL =
      endpoint.indexOf("://") === -1
        ? config.baseURL + "/" + endpoint
        : endpoint;

    let apiResponseMessage = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    );
    apiResponseMessage.addData(
      getName(MessageEnum.RestAPIResponceDataMessage),
      uniqueApiCallId
    );

    try {
      this.setState({ isLoading: true });
      let response = await fetch(fullURL, {
        method: method.toUpperCase(),
        headers: headers,
        body: body
      });
      if (response.status === 401) {
        this.goTo("LoginForm");
      }

      this.setState({ isLoading: false })

      let responseJson = await response.json();
      if (responseJson) {
        this.setState({ isLoading: false })
      }
      //setting Response
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJson
      );
      // if token expires then redirect to login
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJson
      );
    } catch (error) {
      runEngine.debugLog("RestApiClient Error", error);
      //setting Error
      console.log("Api Error" + JSON.stringify(error));
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
        "An error has occuured. Please try again later."
      );
    }
    this.send(apiResponseMessage);
    // Customizable Area End
  }
  // Customizable Area End
}
