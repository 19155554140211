// Customizable Area Start
import React from "react";
import { Grid, Box, IconButton, Button } from "@material-ui/core";
import { Grade } from "./ProgramSchedulerController.web";
import CommonSelect from "../CommonSelect.web";
import { makeStyles } from "@material-ui/core/styles";
import { showIcon, deleteIcon, editPencilIcon, rightScrollIcon, leftScrollIcon } from "../assets";
// Customizable Area End
const configJSON = require("../config.js");

// Customizable Area Start
const useStyles: any = makeStyles(_theme => ({
  // Customizable Area Start
  header: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-around",
    backgroundColor: "#0056A3",
    marginBottom: "16px",
    color: "white",
    height: "64px",
    alignItems: "center",
    borderRadius: "8px",
    padding: '12px 24px',
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
    '@media(max-width:767px)': {
      minWidth: '767px'
    }
  },
  eventRow: {
    flexDirection: "row",
    display: "flex",
    alignItems: 'center',
    padding: '12px 24px',
    backgroundColor: "#FFFFFF",
    marginBottom: "16px",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "24px",
    color: "#3B3B3B",
    cursor: "pointer",
    border: '0.2px solid #e3e3e3',
    '@media(max-width:767px)': {
      minWidth: '767px'
    }
  },
  dataCell: {

  },
  dateDataCell: {
    display: 'flex'
  },
  iconCell: {
    marginLeft: "auto",
    height: "14.6px",
    marginTop: "3px"
  },
  iconButton: {
    height: 20,
    minWidth: 20,
    padding: 5
  },
  unselectedButton: {
    color: '#0056A3',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    textTransform: "none",
    minWidth: 132,
    maxWidth: 132,
    height: 56
  },
  selectGrade: {
    maxHeight: 58,
    display: 'inline-flex',
    flexDirection: 'row',
    '@media(min-width:1280px)':{
      width:'calc(100vw - 487px)',
    },
    width:'calc(100vw - 219px)',
    overflow: 'hidden',
    '@media(max-width:600px)':{
      width:'calc(100vw - 175px)',
    }
  },
  selectedButton: {
    borderRadius: 8,
    background: '#0056A3',
    color: '#FFF',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    textTransform: "none",
    minWidth: 132,
    maxWidth: 132,
    height: 56,
    '&:hover': {
      background: '#0056A3',
      color: '#FFF',
    }
  },
  selectWrapperGrid: {
    '&.MuiGrid-container': {
      marginBottom: '20px'
    }
  },
  tableWrapper: {
    width: '100%',
    '@media(max-width:767px)': {
      overflowX: 'auto',
      width: 'calc(100vw - 127px)'
    },
    '@media(max-width:600px)': {
      width: 'calc(100vw - 84px)'
    }
  },
  actionBtns: {
    marginLeft: 'auto', display: 'flex', justifyContent: 'flex-end'
  },
  // Customizable Area End
}));

interface TableViewProps {
  eventsData: any;
  schoolList?: Array<number | string>;
  courseList?: Array<number | string>;
  childList?: Array<number | string>;
  gradeList: Array<Grade>;
  selectedSchool: string,
  selectedCourse: string,
  selectedChild: string,
  selectedGrade: string,
  handleSchoolSelectChange: (val: string) => void,
  handleCourseSelectChange: (val: string) => void,
  handleChildSelectChange: (val: string) => void,
  handleGradeChange: (val: string) => void,
  openModal: (id: string) => void;
  openDeleteModal: (id: string) => void;
  openEditModal: (id: string) => void;
}
// Customizable Area End
export const TableView = (props: TableViewProps) => {
  // Customizable Area Start
  const classes = useStyles();
  const sideScroll = (
    element: HTMLDivElement | null,
    speed: number,
    distance: number,
    step: number
  ) => {
    if (element) {

      let scrollAmount = 0;
      const slideTimer = setInterval(() => {
        element.scrollLeft += step;
        scrollAmount += Math.abs(step);
        if (scrollAmount >= distance) {
          clearInterval(slideTimer);
        }
      }, speed);
    }
  };
  const contentWrapper = React.useRef(null);
  const user = JSON.parse(`${localStorage.getItem('userDetails')}`);
  const role = user?.attributes?.role;
  return (
    <Grid container>
      {role === configJSON.UserRole.Trainer && (
        <Grid container spacing={2} className={classes.selectWrapperGrid} >
          <Grid item xs={12} sm={6}>
            <CommonSelect
              data-test-id='program-course-select'
              value={props.selectedCourse}
              handleChange={(e: any) => { props.handleCourseSelectChange(e.target.value) }}
              placeholder={configJSON.ProgramSchedule.SelectCourse}
              canCancel
              onCancel={() => props.handleCourseSelectChange("")}
              menuList={props.courseList}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CommonSelect
              data-test-id='program-school-select'
              value={props.selectedSchool}
              handleChange={(e: any) => { props.handleSchoolSelectChange(e.target.value) }}
              placeholder={configJSON.ProgramSchedule.SelectSchool}
              canCancel
              onCancel={() => props.handleSchoolSelectChange("")}
              menuList={props.schoolList}
            />
          </Grid>
        </Grid>
      )}
      {role === configJSON.UserRole.School && props.gradeList.length > 0  && (
        <>
          <Box style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', borderRadius: '8px',
            boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
            marginBottom:'20px',
            padding:'0 15px',
            border:'0.2px solid #e3e3e3'
          }}>
            <IconButton className={classes.iconButton}
              data-test-id="program-school-left-button"
              onClick={() => {
                sideScroll(contentWrapper.current, 25, 100, -10);
              }}><img src={leftScrollIcon} /></IconButton>
            <div className={classes.selectGrade} ref={contentWrapper}>
              {props.gradeList.map((grade: Grade) => {
                return (
                  <Button key={grade.id} className={(props.selectedGrade === grade.id) ? classes.selectedButton : classes.unselectedButton} onClick={() => props.handleGradeChange(grade.id)}>
                    Grade {grade.title}
                  </Button>
                )
              })}
            </div>
            <IconButton className={classes.iconButton} onClick={() => {
              sideScroll(contentWrapper.current, 25, 100, 10);
            }}><img src={rightScrollIcon} /></IconButton>
          </Box>
          <Grid container className={classes.selectWrapperGrid}>
            <Grid item xs={12} sm={6}>
              <CommonSelect
                value={props.selectedCourse}
                handleChange={(e: any) => { props.handleCourseSelectChange(e.target.value) }}
                placeholder={configJSON.ProgramSchedule.SelectCourse}
                canCancel
                onCancel={() => props.handleCourseSelectChange("")}
                menuList={props.courseList}
              />
            </Grid>
          </Grid>
        </>
      )}
      {role === configJSON.UserRole.Parents && (
        <Grid container className={classes.selectWrapperGrid}>
          <Grid item xs={12} sm={6}>
            <CommonSelect
              value={props.selectedChild}
              handleChange={(e: any) => { props.handleChildSelectChange(e.target.value) }}
              placeholder={configJSON.ProgramSchedule.SelectChild}
              canCancel
              onCancel={() => props.handleChildSelectChange("")}
              menuList={props.childList}
            />
          </Grid>
        </Grid>
      )}
      <Box className={classes.tableWrapper}>
        <Grid item xs={12} className={classes.header}>
          <Grid item xs={true} className={classes.dateDataCell}>{configJSON.ProgramSchedule.Date}</Grid>
          <Grid item xs={true} className={classes.dateDataCell}>{configJSON.ProgramSchedule.Time}</Grid>
          {role === configJSON.UserRole.Trainer ? (<>
            <Grid item xs={true} className={classes.dateDataCell}> {configJSON.ProgramSchedule.CourseTopic} </Grid>
            <Grid item xs={true} className={classes.dateDataCell}> {configJSON.ProgramSchedule.SchoolName} </Grid>
            <Grid item xs={true}></Grid>
          </>) : (<>
            <Grid item xs={true} className={classes.dateDataCell}>{configJSON.ProgramSchedule.CourseTopic}</Grid>
            <Grid item xs={true} className={classes.dateDataCell}></Grid>
          </>)}
        </Grid>
        {props.eventsData.length > 0 ? (
          props.eventsData.map((evt: any) => {   
            let evtDate = evt.date.split("-");
            evtDate = evtDate[1]+"-"+evtDate[0]+"-"+evtDate[2];
            return (
              <Grid item xs={12} className={classes.eventRow} key={evt.id} onClick={() => {
                props.openModal(evt.id);
              }}>
                <Grid item xs={true} className={classes.dateDataCell}>{evtDate}</Grid>
                <Grid item xs={true} className={classes.dateDataCell}>{evt.time}</Grid>
                {role === configJSON.UserRole.Trainer ? (<>
                  <Grid item xs={true} className={classes.dateDataCell}> {evt.course_title}</Grid>
                  <Grid item xs={true} className={classes.dateDataCell}>
                    {evt.school_name.map((school: string, index: number) => {
                      const schoolName = `${school}${index !== evt.school_name.length - 1 ? ", " : ""}`
                      return schoolName;
                    })}
                  </Grid>
                  <Grid item xs={true} className={classes.actionBtns}>
                    <IconButton
                      className={classes.iconButton}
                      data-test-id="program-edit-button"
                      onClick={(e: any) => {
                        e.stopPropagation();
                        props.openEditModal(evt.id);
                      }}
                    >
                      <img src={editPencilIcon} />
                    </IconButton>
                    <IconButton
                      className={classes.iconButton}
                      data-test-id="program-delete-button"
                      onClick={(e: any) => {
                        e.stopPropagation();
                        props.openDeleteModal(evt.id);
                      }}
                    >
                      <img src={deleteIcon} />
                    </IconButton>
                  </Grid> </>) : (<>
                    <Grid item xs={true} className={classes.dataCell}>
                      {evt.course_title}
                    </Grid>
                    <Grid item xs={true} className={classes.actionBtns} >
                      <IconButton
                        className={classes.iconButton}
                        data-test-id="program-info-button"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          props.openModal(evt.id);
                        }}
                      >
                        <img src={showIcon} />
                      </IconButton>
                    </Grid>
                  </>)}
              </Grid>
            );
          })
        ) : (
          <Grid item xs={12} className={classes.eventRow}>
            {configJSON.ProgramSchedule.NoScheduledEventsOnTheCalendar}
          </Grid>
        )}
      </Box>
    </Grid>
  );
  // Customizable Area End
};

// Customizable Area Start
// Customizable Area End
