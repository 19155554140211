import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { getStorageData } from "../../../../../framework/src/Utilities";

let config = require("../../../../../framework/src/config");
// Customizable Area Start
// Customizable Area End

export const configJSON = require("../../config");

// Customizable Area Start

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  user_token: any;
  currentPage: string;
  headingThemesArray: any;
  endingThemesArray: any;
  badgesArray: any;
  selectedHeadingTheme: any;
  selectedEndingTheme: any;
  selectedBadge: any;
  ratings: any;
  reportPublishedModal: boolean;
  feedbackForStudents: string;
  areasToFocus: string;
  attendence: string;
  reportData: any;
  errors: any;
  editMode: boolean;
  editSrId: any;
  snackBarIsOpenSr: boolean;
  snackBarMsgSr: string;
  openLoader:boolean;
  // Customizable Area End
}
interface SS { id: any }

export default class EditStudentReportController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  GetHeadingThemeAPICallId: any;
  GetEndingThemeAPICallId: any;
  PostReportCreatedAPICallId: any;
  GetBadgesAPICallId: any;
  GetProgrammeSummaryAPICallId: any;
  GetEditStudentReportDataApiCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      user_token: "",
      currentPage: "Select Heading Theme",
      headingThemesArray: [],
      endingThemesArray: [],
      badgesArray: [],
      selectedHeadingTheme: null,
      selectedEndingTheme: null,
      selectedBadge: null,
      ratings: [],
      reportPublishedModal: false,
      feedbackForStudents: "",
      areasToFocus: "",
      attendence: "",
      reportData: {},
      errors: {
        attendenceError: false,
        areasToFocusError: false,
        stdentFeedbackError: false
      },
      editMode: false,
      editSrId: "",
      snackBarIsOpenSr: false,
      snackBarMsgSr: "",
      openLoader:false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const user_token = await localStorage.getItem("user_token") ?? "";
    this.setState({
      user_token
    });
    this.getStudentReportTheme({ theme_type: "heading_theme" });
    this.getStudentReportTheme({ theme_type: "ending_theme" });
    this.getBadges();
    let responseData;
    getStorageData("student_data", true).then((response: any) => {
      responseData = response;
      const filteredArray = response.mind_mastery_course.skills.filter((item: any) => item !== "");
      const skillsArray = filteredArray.map((el: string) => {
        if (el) {
          return {
            name: el,
            rating: 1
          }
        }
      });

      this.setState({
        ratings: [...skillsArray],
        reportData: {
          student_id: responseData.student_id,
          mind_mastery_course_id: responseData.mind_mastery_course.id,
          school_id: responseData.school.id,
          grade_id: responseData.grade.id,
          section_id: responseData.section.id,
          starting_theme_id: this.state.selectedHeadingTheme,
          ending_theme_id: this.state.selectedEndingTheme,
          badge_id: this.state.selectedBadge,
          areas_to_focus: "",
          feedback_for_students: "",
          programme_summary: {
            attendance: this.state.attendence,
          },
          skills: [...skillsArray]
        }
      });
      this.getProgrammeSummary();
      if (response.status.toLowerCase() !== "pending") {
        this.setState({ editMode: true });
        this.getEditStudentReportData(responseData.report_id);
      }
    })
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const error = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (error) {
        this.setState({ snackBarIsOpenSr: true, snackBarMsgSr: "Error: Report could not be created." })
      }
      if (responseJson && !responseJson.errors) {
        this.apiResponseSuccess(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        console.log("Some Error Occured", responseJson.errors);
        this.setState({ snackBarIsOpenSr: true, snackBarMsgSr: responseJson.errors[0] })
      }
    }
  }

  apiResponseSuccess(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.GetHeadingThemeAPICallId) {
      this.setState({ headingThemesArray: [...responseJson.themes], selectedHeadingTheme: responseJson.themes[0].id });
    }
    if (apiRequestCallId === this.GetEndingThemeAPICallId) {
      this.setState({ endingThemesArray: [...responseJson.themes], selectedEndingTheme: responseJson.themes[0].id });
    }
    if (apiRequestCallId === this.GetBadgesAPICallId) {
      this.setState({ badgesArray: [...responseJson.badges], selectedBadge: responseJson.badges[0].id });
    }
    if (apiRequestCallId === this.PostReportCreatedAPICallId) {
      this.setState({ openLoader: true });
      setTimeout(() => {
        this.setState({ openLoader: false },() => this.setState({reportPublishedModal: true, editMode: false}));
      }, 11000)
    }
    if (apiRequestCallId === this.GetProgrammeSummaryAPICallId) {
      this.setState({
        reportData: {
          ...this.state.reportData,
          programme_summary: {
            ...responseJson.programme_summary,
            ...this.state.reportData.programme_summary,
          },
        }
      })
    }
    if (apiRequestCallId === this.GetEditStudentReportDataApiCallId) {
      this.assignEditData(responseJson);
    }
  }
  goBack = () => {
    switch (this.state.currentPage) {
      case configJSON.Report.SelectHeadingTheme:
        window.history.back();
        break;
      case configJSON.Report.SelectEndingTheme:
        this.setState({ currentPage: configJSON.Report.SelectHeadingTheme });
        break;
      case configJSON.Report.SelectBadge:
        this.setState({ currentPage: configJSON.Report.SelectEndingTheme });
        break;
      case configJSON.Report.StudentsReport:
        this.setState({ currentPage: configJSON.Report.SelectBadge });
        break;
    }
  };

  handleNextClick = () => {
    switch (this.state.currentPage) {
      case configJSON.Report.SelectHeadingTheme:
        this.setState({ currentPage: configJSON.Report.SelectEndingTheme });
        break;
      case configJSON.Report.SelectEndingTheme:
        this.setState({ currentPage: configJSON.Report.SelectBadge });
        break;
      case configJSON.Report.SelectBadge:
        this.setState({ currentPage: configJSON.Report.StudentsReport });
        break;
      case configJSON.Report.StudentsReport:
        this.setState({
          reportData: {
            ...this.state.reportData,
            starting_theme_id: +this.state.selectedHeadingTheme,
            ending_theme_id: +this.state.selectedEndingTheme,
            badge_id: +this.state.selectedBadge,
            areas_to_focus: this.state.areasToFocus,
            feedback_for_students: this.state.feedbackForStudents,
            programme_summary: {
              ...this.state.reportData.programme_summary,
              attendance: +this.state.attendence,
            },
            skills: [...this.state.ratings]
          }
        }, () => {
          this.validateCreateReport();
        });
    }
  }

  validateCreateReport() {
    if (this.state.areasToFocus.trim() === "") {
      this.setState({
        errors: {
          ...this.state.errors,
          areasToFocusError: true,
        }
      })
    }
    if (this.state.feedbackForStudents.trim() === "") {
      this.setState({
        errors: {
          ...this.state.errors,
          stdentFeedbackError: true
        }
      })
    }
    if (this.state.attendence === "") {
      this.setState({
        errors: {
          ...this.state.errors,
          attendenceError: true
        }
      })
    }
    if (this.state.areasToFocus.trim() !== "" && this.state.feedbackForStudents.trim() !== "" && this.state.attendence !== "") {
      this.postCreateReportApiCall();
    }
  }
  postCreateReportApiCall() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.state.user_token
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.PostReportCreatedAPICallId = apiRequest.messageId;
    this.makeApiCall2(
      apiRequest.messageId,
      this.state.editMode ? configJSON.updateMethod : configJSON.httpPostMethod,
      this.state.editMode ? configJSON.getTrainerStudentReportIndex + `/${this.state.editSrId}` : configJSON.getTrainerStudentReportIndex,
      header,
      JSON.stringify(this.state.reportData)
    );
  }

  assignEditData(response: any) {
    const reportData = response.sudent_report.attributes;
    this.setState({
      editSrId: reportData.id,
      ratings: reportData.skills,
      selectedHeadingTheme: reportData.starting_theme_id.toString(),
      selectedEndingTheme: reportData.ending_theme_id.toString(),
      selectedBadge: reportData.badge_id.toString(),
      feedbackForStudents: reportData.feedback_for_students,
      attendence: reportData.programme_summary.attendance,
      areasToFocus: reportData.areas_to_focus,
      reportData: {
        student_id: reportData.student_id,
        mind_mastery_course_id: reportData.mind_mastery_course_id,
        school_id: reportData.school_id,
        grade_id: reportData.grade_id,
        starting_theme_id: reportData.starting_theme_id,
        ending_theme_id: reportData.ending_theme_id,
        badge_id: reportData.badge_id,
        areas_to_focus: reportData.areas_to_focus,
        feedback_for_students: reportData.feedback_for_students,
        programme_summary: {
          ...this.state.reportData.programme_summary,
          attendance: reportData.programme_summary.attendance,
        },
        skills: reportData.skills
      }

    })
  }

  handleHeadingThemeClick = (themeId: any) => {
    this.setState({ selectedHeadingTheme: themeId })
  }

  handleEndingThemeClick = (themeId: any) => {
    this.setState({ selectedEndingTheme: themeId })
  }
  handleSelectBadgeClick = (badgeId: any) => {
    this.setState({ selectedBadge: badgeId })
  }

  closeModal = () => {
        this.setState({ reportPublishedModal: false });
        this.props.navigation.navigate("TrainerStudentReport");
  }

  handleAreasToFocusChange = (e: any) => {
    this.setState({ areasToFocus: e.target.value }, () => {
      if (this.state.areasToFocus.trim() !== "") {
        this.setState({
          errors: {
            ...this.state.errors,
            areasToFocusError: false,
          }
        })
      }
    })
  }

  handleFedbackChange = (e: any) => {
    this.setState({ feedbackForStudents: e.target.value }, () => {
      if (this.state.feedbackForStudents.trim() !== "") {
        this.setState({ errors: { ...this.state.errors, stdentFeedbackError: false } })
      }
    })
  }
  handleAttendenceChange = (e: any) => {
    const regex = /^\d+$/;
    const isMatch = regex.test(e.target.value);
    let value;
    if (isMatch) {
      value = e.target.value;
    } else {
      value = e.target.value.slice(0, -1);
    }
    this.setState({ attendence: value }, () => {
      if (this.state.attendence.trim() !== "") {
        this.setState({ errors: { ...this.state.errors, attendenceError: false } })
      }
    })
  }

  handleSkil1RatingChange = (value: any, skillName: string) => {
    let newRatings;
    newRatings = this.state.ratings.map((item: any) => {
      if (item?.name === skillName) {
        return { ...item, rating: value };
      }
      return item;
    });
    this.setState({ ratings: [...newRatings] });
  }

  onSrSnackbarClose() {
    this.setState({ snackBarIsOpenSr: false, snackBarMsgSr: "" })
  }

  getProgrammeSummary() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.state.user_token
    };

    const params = { mind_mastery_course_id: this.state.reportData.mind_mastery_course_id };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.GetProgrammeSummaryAPICallId = apiRequest.messageId;
    this.makeApiCallStdRp(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.getProgrammeSummaryEndPoint,
      header,
      undefined,
      params
    );
  }

  getStudentReportTheme(params: any) {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.state.user_token
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    params.theme_type === "heading_theme" && (this.GetHeadingThemeAPICallId = apiRequest.messageId);
    params.theme_type === "ending_theme" && (this.GetEndingThemeAPICallId = apiRequest.messageId);
    params.grades = "1-4"
    this.makeApiCallStdRp(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.getheadingThemeAPI,
      header,
      undefined,
      params
    );
  }

  getBadges() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.state.user_token
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.GetBadgesAPICallId = apiRequest.messageId;
    this.makeApiCallStdRp(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.getReportBadgesEndPoint,
      header,
    );
  }

  getEditStudentReportData(id: any) {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.state.user_token
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.GetEditStudentReportDataApiCallId = apiRequest.messageId;
    this.makeApiCallStdRp(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.getTrainerStudentReportIndex + `/${id}/edit`,
      header,
    );
  }

  async makeApiCallStdRp(
    uniqueApiCallIdStdRp: string,
    methodStdRp: string,
    endpointStdRp: string,
    headersStdRp: any,
    bodyStdRp?: any,
    paramsStdRp?: any,
  ) {
    let fullURL =
      endpointStdRp.indexOf("://") === -1
        ? config.baseURL + "/" + endpointStdRp
        : endpointStdRp;

    let apiResponseMessage1 = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    );
    apiResponseMessage1.addData(
      getName(MessageEnum.RestAPIResponceDataMessage),
      uniqueApiCallIdStdRp
    );
    try {
      let response = await fetch(fullURL + "?" + new URLSearchParams({ ...paramsStdRp }).toString(), {
        method: methodStdRp.toUpperCase(),
        headers: headersStdRp,
        body: bodyStdRp
      });
      if (response.status === 401) {
        console.log("401 Response", response);
      }
      let responseJson = await response.json();
      //setting Response
      apiResponseMessage1.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJson
      );
      apiResponseMessage1.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJson
      );
    } catch (error) {
      runEngine.debugLog("RestApiClient Error", error);
      //setting Error
      console.log("Api Error" + JSON.stringify(error));
      apiResponseMessage1.addData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
        "An error has occuured. Please try again later."
      );
    }
    this.send(apiResponseMessage1);
  }
  async makeApiCall2(
    uniqueApiCallId2: string,
    method2: string,
    endpoint2: string,
    headers2: any,
    body2?: any,
    params2?: any,
  ) {
    let fullURL =
      endpoint2.indexOf("://") === -1
        ? config.baseURL + "/" + endpoint2
        : endpoint2;

    let apiResponseMessageStdRp = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    );
    apiResponseMessageStdRp.addData(
      getName(MessageEnum.RestAPIResponceDataMessage),
      uniqueApiCallId2
    );
    try {
      let responseStdRp = await fetch(fullURL, {
        method: method2.toUpperCase(),
        headers: headers2,
        body: body2
      });
      if (responseStdRp.status === 401) {
        console.log("401 Response", responseStdRp);
      }
      let responseJsonStdRp = await responseStdRp.json();
      //setting Response
      apiResponseMessageStdRp.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJsonStdRp
      );
      apiResponseMessageStdRp.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJsonStdRp
      );
    } catch (errorStdRp) {
      runEngine.debugLog("RestApiClient Error", errorStdRp);
      //setting Error
      console.log("Api Error" + JSON.stringify(errorStdRp));
      apiResponseMessageStdRp.addData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
        "An error has occuured. Please try again later."
      );
    }
    this.send(apiResponseMessageStdRp);
  }

  // Customizable Area End
}
