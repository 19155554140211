import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { CometChat } from '@cometchat-pro/chat';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
// Customizable Area Start
let config = require("../../../framework/src/config");
// Customizable Area End

export const configJSON = require("./config");
export const OTP_LENGTH = 6;
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
interface Rules {
  uppercase: boolean;
  lowercase: boolean;
  special: boolean;
  digit: boolean
}
// Customizable Area End
interface S {
  // Customizable Area Start
  step: any;
  accountType: any;
  emailOtp: any;
  hasError: any;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  parentsEmail: string;
  gender: string;
  state:string;
  city:string;
  school: string;
  section: string;
  password: string;
  confirmPassword: string;
  schoolList: any[];
  classList: any[];
  mainClassList: any[];
  genderList: string[];
  cityList: string[];
  stateList: string[];
  termsAndConditionsContent: any;
  datePickerProp: any;
  errors: any;
  showPopup: boolean;
  profileImage: any;
  displayProfileUrl: any;
  openImageDropZone: boolean;
  profileImageSize: any;
  profileImageName: string;
  progress: number;
  showPrivacyPolicy: boolean;
  tempProfileUrl: string;
  aadharImage: any;
  displayAadharUrl: any;
  openAadharImageDropZone: boolean;
  aadharImageSize: any;
  aadharImageName: string;
  tempAadharUrl: string;
  avatarKey: string;
  tempAvatarKey: any;
  tempPwd: string;
  showAPIError: boolean;
  showTempPwd: boolean;
  showConfirmPwd: boolean;
  otpResent: string;
  userRegistered: boolean;
  schoolNextForm:boolean;
  pinCode:string;
  rules:Rules;
  mobileNo:string;
  addressLine1:string;
  addressLine2:string;
  aadharCardNumber:string;
  childrens?:any

  // Customizable Area End
}

// Customizable Area Start
interface SS {
  id: any;
}

interface E {
  firstName?: string,
  lastName?: string,
  email?: string,
  parentsEmail?: string,
  passwordField?: string,
  dob?: string,
  confirmPassword?: string,
  gender?: string,
  school?: string,
  notmatching?: string,
  section?: string,
  mobileNo?:string,
  addressLine1?:string,
  addressLine2?:string,
  pinCode?:string
  city?:string
  state?:string
  aadharImageName?:string
  aadharCardNumber?:string
}
// Customizable Area End
export default class SignupPageController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  otpFieldRef: any;
  schoolListAPICallId: any = "";
  sectionListAPICallId: any = "";
  createAccountAPICallId: any = "";
  termsAndConditionsAPICallId: any = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      step: 0,
      accountType: "",
      emailOtp: "",
      hasError: false,
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      email: "",
      parentsEmail: "",
      profileImage: null,
      gender: "Gender",
      city: "City",
      state: "State",
      school: "School Name",
      section: "Class",
      password: "",
      tempPwd: "",
      datePickerProp: null,
      confirmPassword: "",
      schoolList: [],
      classList: [],
      mainClassList: [],
      showPopup: false,
      termsAndConditionsContent: null,
      genderList: ["Female", "Male", "Non-binary", "Other"],
      cityList: ["Female", "Male", "Non-binary", "Other"],
      stateList: ["Female", "Male", "Non-binary", "Other"],
      pinCode:"",
      errors: {},
      displayProfileUrl: '',
      openImageDropZone: false,
      profileImageName: '',
      profileImageSize: '',
      progress: 0,
      tempProfileUrl: '',
      displayAadharUrl: '',
      openAadharImageDropZone: false,
      aadharImageName: '',
      aadharImageSize: '',
      tempAadharUrl: '',
      aadharImage:"",
      avatarKey: '',
      showPrivacyPolicy: false,
      tempAvatarKey: '',
      showAPIError: false,
      showTempPwd: false,
      showConfirmPwd: false,
      otpResent: '',
      userRegistered: false,
      schoolNextForm:true,
      rules:{
          uppercase: false,
          lowercase: false,
          digit: false,
          special: false
        },
        addressLine1:"",
        addressLine2:"",
        mobileNo:"",
        aadharCardNumber:"",
        childrens:[]


    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.otpFieldRef = [];
    // Customizable Area End
  }



  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let userId = params.get('user_id');
    let fullURL =
      configJSON.userDetailsApi.indexOf("://") === -1
        ? config.baseURL +  configJSON.userDetailsApi
        : configJSON.userDetailsApi;
    const header = {
      "Content-Type": configJSON.userDetailsApiContentType
    };
    if (userId) {
      try {
        const res = await fetch(fullURL + `/${userId}`, {
          method: configJSON.userDetailsApiMethod.toUpperCase(),
          headers: header,
        });
        const data = await res.json();
        const userData = data.data.attributes;
        const dob = new Date(userData.birthday);
        if(!userData.registered)
        {
        await (async () => {
          this.setState({
            firstName: userData.first_name,
            lastName: userData.last_name,
            email: userData.email,
            parentsEmail: userData.parents_email,
            datePickerProp: dob,
            dateOfBirth: userData.birthday,
            gender: userData.gender,
            school: userData.school_name,
            section: userData.school_class,
            mobileNo: userData.mobile_number,
            addressLine1: userData.address_line1,
            addressLine2: userData.address_line2,
            accountType: userData.role ? userData.role.charAt(0).toUpperCase() + userData.role.slice(1): "Student",
            city:userData.city,
            state:userData.state,
            pinCode:userData?.pincode ? userData.pincode.toString() :"" ,
            childrens:userData?.childrens

          })
        })();
      }else{
        alert("User is already Registered")
      }
      }
      catch (e) {
        console.error(e);
      }
    }


    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          switch (apiRequestCallId) {
            case this.schoolListAPICallId: this.setState({ schoolList: responseJson.data }); break;
            case this.sectionListAPICallId: this.setState({ mainClassList: responseJson.data }); break;
            case this.termsAndConditionsAPICallId: this.setState({ termsAndConditionsContent: responseJson.data }); break;
            case this.createAccountAPICallId: console.log(responseJson);
          }
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      }
      if (errorReponse) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async apiCall(method: string, endpoint: string, headers: any, body: string) {
    let fullURL =
      endpoint.indexOf("://") === -1
        ? config.baseURL + "/" + endpoint
        : endpoint;
    let apiResponseMessage = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    );
    let response: any;
    try {
      if (headers && body) {
        response = await fetch(fullURL, {
          method: method.toUpperCase(),
          headers: headers,
          body: body
        });
      }
      let responseJson = await response.json();
      if (responseJson.message === "Invalid OTP.") {
        return false;
      }
      if (responseJson.error === "unauthorized") {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      runEngine.debugLog("RestApiClient Error", error);
      //setting Error
      console.log("Api Error" + JSON.stringify(error));
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
        "An error has occuured. Please try again later."
      );
      return false;
    }
  }
handleNext=()=>{


  let errors: E = {
    firstName: "",
    lastName: '',
    email: '',
    confirmPassword:"",
    passwordField: '',
    notmatching: '',
    school: '',
    gender: '',
    dob:"",
  };
  const regxForText = /^[a-zA-Z]+(?: [a-zA-Z]+)?$/;
  const emailRegx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const passwordRegx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,}$/;
  if(this.state.accountType !== "School"){
    errors.firstName = this.validateRegFields(this.state.firstName, regxForText, 'First Name');
    errors.lastName = this.validateRegFields(this.state.lastName, regxForText, 'Last Name');
  }
  errors.email = this.validateRegFields(this.state.email, emailRegx, 'Email')
  errors.passwordField = this.validateRegFields(this.state.tempPwd, passwordRegx, 'Password')

  if (!this.state.confirmPassword) {
    errors.confirmPassword = "Confirm password is required!";
  } else if (this.state.tempPwd !== this.state.confirmPassword) {
    errors.notmatching = "Password does not match!";
    if(this.state.accountType=="Trainer"){
          if (!this.state.dateOfBirth) {
      errors.dob = "Date of birth required!";
    }
    errors.gender = this.prefilledValueValidation(this.state.gender, 'Gender');
    }
    else{
      errors.school = this.prefilledValueValidation(this.state.school, 'School Name');

    }
  }
  errors = Object.entries(errors).reduce((a: E, [k, v]) => (v ? (a[k as keyof E] = v, a) : a), {})
  if (Object.keys(errors).length === 0) {
   
     this.setState({schoolNextForm:!this.state.schoolNextForm})

  } else {
    this.setState({ ...this.state, errors: errors });
  }

}

  handleOpenImageDropZone = () => {
    this.setState({ openImageDropZone: true });
  }

  handleCloseImageDropZone = () => {
    this.setState({ openImageDropZone: false, profileImageName: '', profileImageSize: '', progress: 0 ,tempProfileUrl:''})
  }
  handleOpenAadharImageDropZone = () => {
    this.setState({ openAadharImageDropZone: true });
  }

  handleCloseAadharImageDropZone = () => {
    this.setState({ openAadharImageDropZone: false, aadharImageSize: '', progress: 0 })
  }

  handleFormFieldChange = (event: any) => {
    const { name, value } = event.target;
    this.setState({ ...this.state, [name]: value });
  };

  handleDOB = (date: any) => {
    const DateOfBirth = moment(date).format("DD-MM-YYYY");
    this.setState({ datePickerProp: date, dateOfBirth: DateOfBirth });
  };

  handleUploadProfilePhoto = async (event: any) => {
    const file = event[0];
    const fileSize = file.size;
    const fileName = file.name;
    const size = this.formatFileSize(fileSize);
    const url = URL.createObjectURL(file);
    this.setState({ profileImage: file, tempProfileUrl: url, profileImageName: fileName, profileImageSize: size, progress: 0 }, () => this.simulateProgress());
  }


  handleUploadAadharPhoto = async (event: any) => {
    const file = event[0];
    const fileSize = file.size;
    const fileName = file.name;
    const size = this.formatFileSize(fileSize);
    const url = URL.createObjectURL(file);
    this.setState({ aadharImage: file, tempAadharUrl: url, aadharImageName: fileName, aadharImageSize: size, progress: 0 }, () => this.simulateProgress());
  }


  handleImageUploadDone = () => {
    this.setState({ displayProfileUrl: this.state.tempProfileUrl })
    this.handleCloseImageDropZone();
  }


  handleAadharImageUploadDone = () => {
    this.setState({ displayAadharUrl: this.state.tempAadharUrl })
    this.handleCloseAadharImageDropZone();
  }

 handlePwdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length > 0) {
      let newRules = this.state.rules;
      if (/[A-Z]/.test(value)) {
        newRules = { ...newRules, uppercase: true };
      } else {
        newRules = { ...newRules, uppercase: false };
      }
      if (/[a-z]/.test(value)) {
        newRules = { ...newRules, lowercase: true };
      } else {
        newRules = { ...newRules, lowercase: false };
      }
      if (/\d/.test(value)) {
        newRules = { ...newRules, digit: true };
      } else {
        newRules = { ...newRules, digit: false };
      }
      if (/\W/.test(value)) {
        newRules = { ...newRules, special: true };
      } else {
        newRules = { ...newRules, special: false };
      }
      this.setState({rules:newRules})
    }
    this.handleFormFieldChange(e);
  }

  simulateProgress = () => {
    let progressCounter = 0;
    const progressInterval = setInterval(() => {
      progressCounter += 10;
      if (progressCounter <= 100) {
        this.setState({ progress: progressCounter });
      } else {
        clearInterval(progressInterval);
      }
    }, 10);
  };

  handleCancelUpload = () => {
    this.setState({ profileImageName: '', profileImageSize: '', progress: 0, tempProfileUrl: '', tempAvatarKey: '' })
  }
  handleCancelAdharUpload = () => {
    this.setState({ aadharImageName: '', aadharImageSize: '', progress: 0, tempAadharUrl: '', })
  }


  handleClickShowPwd = () => {
    this.setState({showTempPwd: !this.state.showTempPwd});
  }

  handleClickShowConfirmPwd = () => {
    this.setState({showConfirmPwd: !this.state.showConfirmPwd});
  }

  formatFileSize = (size: number) => {
    if (size < 1024) {
      return size + " bytes";
    } else if (size < 1048576) {
      return (size / 1024).toFixed(2) + " Kb";
    } else if (size < 1073741824) {
      return (size / 1048576).toFixed(2) + " Mb";
    }
  }

  handleChangeSelectMenu = (menuType: string, value: any) => {
    switch (menuType) {
      case "gender":
        this.setState({ gender: value });
        break;
      case "school":
        let key = Object.keys(value);
        let val: number[] = Object.values(value);
        let classes = this.state.mainClassList;

        classes = classes.filter(e => {
          return e.attributes.school_id === val[0];
        });

        this.setState({
          school: key[0],
          section: "Class",
          classList: classes
        });
        break;
      case "class":
        this.setState({ section: value });
        break;
      default:
        this.setState({ ...this.state });
    }
  };

  handleSelectAccountType = (e: any) => {
    if (this.state.accountType === e.target.value) {
      this.setState({ step: 1 });
    }
  };
  createAccountApiCall=async(errors:E)=>{
  errors=Object.entries(errors).reduce((a: E, [k, v]) => (v ? (a[k as keyof E] = v, a) : a), {})
    if (Object.keys(errors).length === 0) {
      const createApiCall = await this.createAccount();
      if(createApiCall) {
        this.setState({showAPIError: false});
        this.setState({ step: 2 });
      } else {
        this.setState({showAPIError: true});
      }
    } else {
      this.setState({ ...this.state, errors: errors });
    }
}



   disableFutureDates = (date: any) => {
    const currentDate = new Date();
    if (date > currentDate) {
      return true;
    }
    return false;
  };

  handleSubmitCreateAccount = async () => {
    let errors: E = {
      email: '',
      parentsEmail: '',
      dob: '',
      passwordField: '',
      notmatching: '',
      gender: '',
      school: '',
      section: ''
    };
    const emailRegx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const passwordRegx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,}$/;
    errors.email = this.validateRegFields(this.state.email, emailRegx, 'Email')
    errors.parentsEmail = this.validateRegFields(this.state.parentsEmail, emailRegx, 'Parent Email')
    errors.passwordField = this.validateRegFields(this.state.tempPwd, passwordRegx, 'Password')
    if (!this.state.dateOfBirth) {
      errors.dob = "Date of birth required!";
    }
    if (!this.state.confirmPassword) {
      errors.confirmPassword = "Confirm password is required!";
    } else if (this.state.tempPwd !== this.state.confirmPassword) {
      errors.notmatching = "Password does not match!";
    }
    errors.gender = this.prefilledValueValidation(this.state.gender, 'Gender');
    errors.school = this.prefilledValueValidation(this.state.school, 'School Name');
    errors.section = this.prefilledValueValidation(this.state.section, 'Class');
    this.createAccountApiCall(errors)
  };
  handleSubmitCreateAccountForSchool = async () => {
    let errors: E = {
     addressLine1:"",
     addressLine2:"",
     city:"",
     state:"",
     pinCode:"",
     aadharCardNumber:"",
     aadharImageName:""
    };
    const aadharRegx=/^\d{12}$/

  errors.addressLine1 = this.prefilledValueValidation(this.state.addressLine1, 'Address Line 1');
  errors.addressLine2 = this.prefilledValueValidation(this.state.addressLine2, 'Address Line 2');
  errors.city = this.prefilledValueValidation(this.state.city, 'City');
  errors.state = this.prefilledValueValidation(this.state.state, 'State');
  errors.pinCode = this.prefilledValueValidation(this.state.pinCode, 'Pin Code');
  if(this.state.accountType=="Trainer")
  {
    errors.aadharCardNumber = this.validateRegFields(this.state.aadharCardNumber,aadharRegx,'Aadhar Card Number');
    errors.aadharImageName = this.emptyValidation(this.state.aadharImageName, 'Aadhar Card Photo');

  }
  this.createAccountApiCall(errors)

    
   
  };
  togglePopup = (show = false) => {

    this.setState({ showPopup: !this.state.showPopup, showPrivacyPolicy: show });
  }

  validateRegFields = (state: any, regularExp: RegExp, name: string) => {
    if (!state) {
      return `${name} is required!`;
    } else if (!regularExp.test(state)) {
      return `${name} is not valid!`;
    }
  }

  prefilledValueValidation = (state: any, name: string) => {

    if (state == name) {
      return `${name} is required!`;
    }
  }

  emptyValidation = (state: any, name: string) => {
    if (!state) {
      return `${name} is required!`;
    }
  }

  handleClearOTP=()=>{
    this.setState({emailOtp:""})
  }

  handleInputOtp = (e: any, index: any) => {
    const RE_DIGIT = new RegExp(/^\d+(\s\d+)*$/);
    const target = e.target;
    const value = target.value;
    const { emailOtp } = this.state;
  

    if (!value) {
      this.setState({ emailOtp: emailOtp.substring(0, index) + emailOtp.substring(index+1) });
    }

    if (!RE_DIGIT.test(value)) {
      return;
    }
    const nextElementSibling = target.nextElementSibling;
    if (index < OTP_LENGTH - 1) {
      this.otpFieldRef[index + 1].focus();
    }
    if (nextElementSibling) {
      nextElementSibling.focus();
    }

    const newValue = emailOtp + value;

    this.setState({ emailOtp: newValue });
  };

  getOtpArray = () => {
    const { emailOtp } = this.state;
    const RE_DIGIT = new RegExp(/^\d+$/);
    let arr = emailOtp.split("");
    let items: any = [];

    for (let i = 0; i < OTP_LENGTH; i++) {
      const char = arr[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }

    return items;
  };

  createStudentAccountSubmitHandler = () => {
    this.setState({ step: 3 });
  };
  
  getTermsAndConditions = () => {
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.termsAndConditionsAPICallId = apiRequest.messageId;
    this.makeApiCall(
      this.termsAndConditionsAPICallId,
      configJSON.termsAndConditionsApiMethodType,
      configJSON.termsAndConditionsApiEndPoint
    );
    return true;
  };

 async createAccount() {
  const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.createAccountAPICallId = apiRequest.messageId;
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let userId = params.get('user_id');

  let data = {
    first_name: this.state.firstName,
    last_name: this.state.lastName,
    email: this.state.email,
    profile_photo: null,
    avatar_key: this.state.avatarKey,
    password: this.state.tempPwd,
    school_name: this.state.school,
    password_confirmation: this.state.confirmPassword
  };

  if (this.state.profileImage || this.state.aadharImage) {
    const formData = this.getFormDataForProfileOrAadhar();

    const result = await this.makeApiCall(
      this.createAccountAPICallId,
      configJSON.createAccountApiMethodType,
      `${configJSON.createAccountApiEndPoint}/${userId}`,
      {},
      formData
    );

    if (result) {
      this.createCometChatUser(result.profile_photo_url);
      return true;
    } else {
      return false;
    }
  } else {
    data = this.getUpdatedDataForAccountType(data);
    const header = { "Content-Type": configJSON.createAccountApiContentType };

    const result = await this.makeApiCall(
      this.createAccountAPICallId,
      configJSON.createAccountApiMethodType,
      `${configJSON.createAccountApiEndPoint}/${userId}`,
      header,
      JSON.stringify(data)
    );

    if (result) {
      this.createCometChatUser();
      return true;
    } else {
      return false;
    }
  }
}

getFormDataForProfileOrAadhar() {
  const formData = new FormData();
  formData.append("first_name", this.state.firstName);
  formData.append("last_name", this.state.lastName);
  formData.append("email", this.state.email);
  formData.append("school_name", this.state.school);
  formData.append("password", this.state.tempPwd);

  if (this.state.profileImage) {
    formData.append("profile_photo", this.state.profileImage);
  }

  formData.append("avatar_key", '');

  if (this.state.accountType == "School" || this.state.accountType == "Trainer") {
    formData.append("address_line1", this.state.addressLine1);
    formData.append("address_line2", this.state.addressLine2);
    formData.append("state", this.state.state);
    formData.append("city", this.state.city);
    formData.append("pincode", this.state.pinCode);
    formData.append("mobile_number", this.state.mobileNo);
  } else {
    formData.append("birthday", this.state.dateOfBirth);
    formData.append("parents_email", this.state.parentsEmail);
    formData.append("gender", this.state.gender);
    formData.append("school_class", this.state.section);
  }

  if (this.state.accountType == "Trainer") {
    formData.append("birthday", this.state.dateOfBirth);
    formData.append("aadhar_card_number", this.state.aadharCardNumber);
    formData.append("aadhar_card_photo", this.state.aadharImage);
    formData.append("password_confirmation", this.state.confirmPassword);
  }

  return formData;
}

getUpdatedDataForAccountType(data: { first_name: string; last_name: string; email: string; profile_photo: null; avatar_key: string; password: string; school_name: string; password_confirmation: string }) {
  if (this.state.accountType == "School") {
    const schoolData = {
      address_line1: this.state.addressLine1,
      address_line2: this.state.addressLine2,
      city: this.state.city,
      state: this.state.state,
      pincode: this.state.pinCode,
      mobile_number: this.state.mobileNo,
    };
    data = { ...data, ...schoolData };
  } else {
    const userData = {
      birthday: this.state.dateOfBirth,
      parents_email: this.state.parentsEmail,
      gender: this.state.gender,
      school_class: this.state.section,
      city: this.state.city || ""
    };
    data = { ...data, ...userData };
  }

  return data;
}

  

  async makeApiCall(
    uniqueApiCallId: string,
    method: string,
    endpoint: string,
    headers?: any,
    body?: any
  ) {
    // Customizable Area Start
    let fullURL =
      endpoint.indexOf("://") === -1
        ? config.baseURL + "/" + endpoint
        : endpoint;

    let apiResponseMessage = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    );
    apiResponseMessage.addData(
      getName(MessageEnum.RestAPIResponceDataMessage),
      uniqueApiCallId
    );
  
    try {
      let response = await fetch(fullURL, {
        method: method.toUpperCase(),
        headers: headers,
        body: body,
      });
      let responseJson = await response.json();
      if (responseJson.errors && responseJson.errors.length > 0 || responseJson.status >= 400) {
        if(responseJson.errors === "Password already updated.") {
          this.setState({userRegistered: true});
        }
        return false;
      } else {
        return responseJson;
      }
    } catch (error) {
      runEngine.debugLog("RestApiClient Error", error);
      //setting Error
      console.log("Api Error" + JSON.stringify(error));
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
        "An error has occurred. Please try again later."
      );
    }

    this.send(apiResponseMessage);
    return false;
    // Customizable Area End
  }

  verifyOTPSubmitHandler = async () => {
    if (this.state.emailOtp.length !== OTP_LENGTH) {
      this.setState({ hasError: true });
      return;
    }
    const header = {
      "Content-Type": configJSON.verifyOTPApiContentType
    };
    const attrs = {
      email: this.state.email,
      otp: this.state.emailOtp
    };
    const response = await this.apiCall(
      configJSON.verifyOTPApiMethod,
      configJSON.verifyOTPApi,
      header,
      JSON.stringify(attrs)
    );
    if (response) {
      this.setState({ step: 3 });
    } else {
      this.setState({ hasError: true });
    }
  };

  handleResendOTPHandler = async () => {
    const header = {
      "Content-Type": configJSON.resendOTPApiContentType
    };
    const attrs = {
      email: this.state.email
    };
    const response = await this.apiCall(
      configJSON.resendOTPApiMethod,
      configJSON.resendOTPApi,
      header,
      JSON.stringify(attrs)
    );
    if(response) {
      this.setState({otpResent: "OTP resent to your mail"});
    } else {
      this.setState({otpResent: "Unable to send the OTP currently"});
    }
    setTimeout(()=>{
      this.setState({otpResent: ""});
    }, 10000);
  };

  handleRedirectToLogin = () => {
    window.location.assign("/LoginForm");
  };

  createCometChatUser = async (imageUrl: string | null = null) => {
    const appSetting = new CometChat.AppSettingsBuilder()
    .subscribePresenceForAllUsers()
    .setRegion(config.region)
    .autoEstablishSocketConnection(true)
    .build();

    let roleID = "default"
    if(this.state.accountType){
      roleID = this.state.accountType;
    }

    CometChat.init(config.appID, appSetting);
    let user = new CometChat.User(new URLSearchParams(window.location.search).get('user_id'));
    user.setName(this.state.firstName+' '+this.state.lastName);
    user.setRole(roleID) //Role ID is in cometchat dashboard
    if(imageUrl){
      user.setAvatar(imageUrl);
    }
    CometChat.createUser(user, config.authKey).then(
    (user) => {
      console.log("User created successfully:", user);
    },
    (error) => {
      console.log("User creation failed with error:", error);
    });
  }
  // Customizable Area End
}
