import React from 'react'
// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    TextField,
    Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AnalysisReportController from "./AnalysisReportController.web";
import PageContentLayout from "../../PageContentLayout.web";
import SidebarLayout from "../../SidebarLayout.web";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from '../../../../../components/src/Loader.web';
import CommonSelect from '../../CommonSelect.web';
import InfoModal from '../../CommonComponents/InformationModal.web';

// Customizable Area End
const configJSON = require("../../config.js");

// Customizable Area Start

const useStyles = makeStyles({
    modalDialog: {
        "& div": {
            "& .MuiDialog-paperWidthSm": {
                width: "49.65vw !important"
            }
        }
    },
    label: {
        color: '#3B3B3B',
        fontSize: '20px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        marginBottom: '7px',
        '@media(max-width:600px)': {
            fontSize: "14px"
        }
    },
    required: {
        color: "red"
    },
    heading: {
        background: "#0056A3",
        borderRadius: "8px",
        color: "#fff",
        fontWeight: "bold",
        padding: '11px 10px 11px 24px',
        marginBottom: '32px'
    }
});

export const EditForm = (props: any) => {
    const classes = useStyles();
    const badgeDropdownList = props.mindId?.map((element: any) => {
        return {
            id: element,
            title: element,
            value: element
        }
    })

    const schooleDropdownList = props.schoolId?.map((element: any) => {
        return {
            id: element,
            title: element,
            value: element
        }
    })

    return (

        <Box p={4}>
            <Formik
                initialValues={{
                    introduction: props.introduction,
                    keyConcerns: props.keyConcerns,
                    Executive: props.executive_summary,
                    focusAreas: props.focusAreas,
                    priority: props.priority,
                    skillset: props.skillset,
                    Ahead01: props.Ahead01,
                    Ahead02: props.Ahead02,
                    Ahead03: props.Ahead03,
                    Ahead04: props.Ahead04,
                    summary1: props.summary1,
                    summary2: props.summary2,
                    Conclusion: props.Conclusion,
                    academicYear: props.academicYear,
                    incidents:props.incidents,
                }}
                validationSchema={Yup.object().shape({
                    focusAreas: Yup.string().required(configJSON.AnalysisReport.FocusAreasRequired)
                    .max(650, "Focus area cannot be exceed the word limit"),
                    priority: Yup.string().required(configJSON.AnalysisReport.PriorityRequired)
                    .max(650, "Priority Module cannot be exceed the word limit"),
                    introduction: Yup.string().required(configJSON.AnalysisReport.IntroductionRequired)
                    .max(1270, "Introduction cannot exceed the word limit"),
                    keyConcerns: Yup.string().required(configJSON.AnalysisReport.KeyConcernsRequired)
                    .max(482, "Key Concern cannot be exceed the word limit"),
                    skillset: Yup.string().required(configJSON.AnalysisReport.SkillsetRequired)
                    .max(482, "Key Skillset cannot be exceed the word limit"),
                    Conclusion: Yup.string().required(configJSON.AnalysisReport.ConclusionRequired)
                    .max(1615, "Conclusion cannot be exceed the word limit"),
                    incidents: Yup.string().required(configJSON.AnalysisReport.keyIncidentRequired)
                    .max(482, "Key Incident cannot be exceed the word limit")
                })}
                onSubmit={(values) => { props.OnCreate(values) }}
            >
                {({ values, setFieldValue ,setFieldTouched }) => (
                    <Form>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <CommonSelect
                                    label={configJSON.AnalysisReport.ProgramNameLabel}
                                    menuList={badgeDropdownList}
                                    name={configJSON.AnalysisReport.ProgramName}
                                    handleChange={props.handleFilterChange}
                                    value={props.courseFilter}
                                    placeholder={configJSON.AnalysisReport.EnterProgramName}
                                    disabled={true}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                        <CommonSelect
                                            placeholder={configJSON.AnalysisReport.EnterSchoolName}
                                            label={configJSON.AnalysisReport.SchoolNameLabel}
                                            menuList={schooleDropdownList}
                                            name={configJSON.AnalysisReport.SchoolName}
                                            handleChange={props.handleSchoolChange}
                                            value={props.schoolFilter}
                                            disabled={true}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <CommonSelect
                                            placeholder={configJSON.TimelyProgress.EnterAcademicYear}
                                            label={`${configJSON.TimelyProgress.AcademicYear}*`}
                                            menuList={props.academicYearLists}
                                            name={configJSON.AnalysisReport.AcademicYear}
                                            handleChange={props.handleAcademicChange}
                                            value={props.academicYears}
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <label className={classes.label} htmlFor="introduction">{configJSON.AnalysisReport.IntroductionLabel}</label>
                                <Field
                                    id="introduction"
                                    name={configJSON.AnalysisReport.Introduction}
                                    variant="outlined"
                                    as={TextField}
                                    multiline
                                    minRows={2}
                                    fullWidth
                                    value={values.introduction}
                                    onChange = {(event:React.ChangeEvent<HTMLInputElement>) => props.handleIntroductionChange(event,setFieldValue,setFieldTouched)}
                                    helperText={
                                        <p className={classes.required}><ErrorMessage name={configJSON.AnalysisReport.Introduction} /></p>
                                    }
                                    placeholder={configJSON.AnalysisReport.EnterIntroduction}
                                />
                            </Grid>
                        </Grid>
                        <Box mt={4}>
                        <Box className={classes.heading}>
                            <Typography>{configJSON.AnalysisReport.analysis}</Typography>
                        </Box>

                            <Grid container style={{ marginTop: "10px" }}>
                                <Grid item xs={12}>
                                    <Box>
                                        <label className={classes.label} htmlFor="keyConcerns">
                                            {configJSON.AnalysisReport.KeyConcernsLabel}
                                        </label>
                                        <Field
                                            as={TextField}
                                            id="keyConcerns"
                                            name={configJSON.AnalysisReport.KeyConcerns}
                                            variant="outlined"
                                            multiline
                                            rows={2}
                                            fullWidth
                                            placeholder={configJSON.AnalysisReport.EnterKeyConcerns}
                                            value={values.keyConcerns}
                                            onChange = {(event:React.ChangeEvent<HTMLInputElement>) => props.handleKeyConcernChange(event,setFieldValue,setFieldTouched)}
                                            helperText={
                                                <p className={classes.required}>
                                                    <ErrorMessage name={configJSON.AnalysisReport.KeyConcerns} />
                                                </p>
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        <label className={classes.label} htmlFor="incidents">
                                            {configJSON.AnalysisReport.keyIncidentLable}
                                        </label>
                                        <Field
                                            as={TextField}
                                            name={configJSON.AnalysisReport.incident}
                                            id="incident"
                                            variant="outlined"
                                            multiline
                                            rows={2}
                                            fullWidth
                                            placeholder={configJSON.AnalysisReport.enterKeyIncident}
                                            value={values.incidents}
                                            onChange = {(event:React.ChangeEvent<HTMLInputElement>) => props.handleKeyIncidentChange(event,setFieldValue,setFieldTouched)}
                                            helperText={
                                                <p className={classes.required}>
                                                    <ErrorMessage name={configJSON.AnalysisReport.incident} />
                                                </p>
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        <label className={classes.label} htmlFor="skillset">
                                            {configJSON.AnalysisReport.SkillsetNeededLabel}
                                        </label>
                                        <Field
                                            as={TextField}
                                            id="skillset"
                                            name={configJSON.AnalysisReport.Skillset}
                                            variant="outlined"
                                            multiline
                                            rows={2}
                                            fullWidth
                                            placeholder={configJSON.AnalysisReport.EnterSkillsetNeeded}
                                            value={values.skillset}
                                            onChange = {(event:React.ChangeEvent<HTMLInputElement>) => props.handleSkilletChange(event,setFieldValue,setFieldTouched)}
                                            helperText={
                                                <p className={classes.required}>
                                                    <ErrorMessage name={configJSON.AnalysisReport.Skillset} />
                                                </p>
                                            }
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                    </Box>

                    <Box mt={4}>
                        <Box className={classes.heading}>
                            <Typography > {configJSON.AnalysisReport.RecommendationsAndRequirements}</Typography>
                        </Box>
                        <Grid item xs={12}>
                                <Box>
                                    <label className={classes.label} htmlFor="focusAreas">
                                        {configJSON.AnalysisReport.FocusAreasLabel}
                                    </label>
                                    <Field
                                        as={TextField}
                                        id="focusAreas"
                                        name={configJSON.AnalysisReport.FocusAreas}
                                        variant="outlined"
                                        multiline
                                        rows={2}
                                        fullWidth
                                        placeholder={configJSON.AnalysisReport.EnterFocusAreas}
                                        value={values.focusAreas}
                                        onChange = {(event:React.ChangeEvent<HTMLInputElement>) => props.handleFocusAreaChange(event,setFieldValue,setFieldTouched)}
                                        helperText={
                                            <p className={classes.required}>
                                                <ErrorMessage name={configJSON.AnalysisReport.FocusAreas} />
                                            </p>
                                        }
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <label className={classes.label} htmlFor="priority">
                                        {configJSON.AnalysisReport.PriorityModulesLabel}
                                    </label>
                                    <Field
                                        as={TextField}
                                        id="priority"
                                        name={configJSON.AnalysisReport.Priority}
                                        variant="outlined"
                                        multiline
                                        rows={2}
                                        fullWidth
                                        placeholder={configJSON.AnalysisReport.EnterPriorityModules}
                                        value={values.priority}
                                        onChange = {(event:React.ChangeEvent<HTMLInputElement>) => props.handlePriorityModueChange(event,setFieldValue,setFieldTouched)}
                                        helperText={
                                            <p className={classes.required}>
                                                <ErrorMessage name={configJSON.AnalysisReport.Priority} />
                                            </p>
                                        }
                                    />
                                </Box>
                            </Grid>
                    </Box>
                        <Box mt={4}>
                            <Box id="editNeedPageConclusion" className={classes.heading}>
                                <Typography id="editNeedPageConclusionText" > {configJSON.AnalysisReport.Conclusion}</Typography>
                            </Box>
                            <Box mt={4}>
                                <Field
                                    as={TextField}
                                    id="Conclsion"
                                    name={configJSON.AnalysisReport.Conclusion}
                                    variant="outlined"
                                    multiline
                                    minRows={4}
                                    fullWidth
                                    placeholder={configJSON.AnalysisReport.EnterText}
                                    value={values.Conclusion}
                                    onChange = {(event:React.ChangeEvent<HTMLInputElement>) => props.handleConclusionChange(event,setFieldValue,setFieldTouched)}
                                    helperText={
                                        <p id="editNeedPageConclusionErrort" className={classes.required}>
                                            <ErrorMessage name={configJSON.AnalysisReport.Conclusion} />
                                        </p>
                                    }
                                />
                            </Box>
                        </Box>
                        <Box
                            mt={4}
                            display={"flex"}
                            style={{ gap: "10px", textAlign: "center" }}
                            justifyContent={"end"}
                        >
                            <Button
                                data-test-id="updatebtn"
                                type="submit"
                                style={webStyle.submitEditNeed}
                                size="large"
                            >
                                Update
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
            <InfoModal
                modalType={configJSON.ModalTypes.Success}
                open={props.Editsuccess}
                closeModal={props.handleEditSuccessClose}
                title={configJSON.AnalysisReport.ThankYou}
                info={configJSON.AnalysisReport.SuccessfulSubmissionMsg}
            />
        </Box>
    )
}
// Customizable Area End

export default class EditReaderForm extends AnalysisReportController {
        // Customizable Area Start
    async componentDidMount(): Promise<void> {
        const EditDataId = Number(this.props.navigation.getParam('EditId'));
        this.setState({ paramsId: EditDataId })
        this.CourseSchool();
        this.getEditAnalysisData(EditDataId);
    }
        // Customizable Area End
    render() {
        // Customizable Area Start
        const editPropsData = {
            OnCreate: this.OnUpdate,
            handleEditSuccessClose: this.handleEditSuccessClose,
            Editsuccess: this.state.Editsuccess,
            editProgramCourse: this.state.editProgramCourse?.name,
            editSchoolCourse: this.state.editSchoolCourse?.name,
            academicYear: this.state.editList?.attributes?.academic_year,
            executive_summary: this.state.editList ? (this.state.editList.attributes?.executive_summary) : (""),
            introduction: this.state.editList.attributes?.introduction,
            keyConcerns: this.state.editList.attributes?.analysis?.key_concerns,
            focusAreas: this.state.editList.attributes?.recommendations_and_requirements?.focus_areas,
            priority: this.state.editList.attributes?.recommendations_and_requirements?.priority_modules,
            skillset: this.state.editList.attributes?.analysis?.skillset_needed,
            Ahead01: this.state.editList.attributes?.way_ahead?.o1,
            Ahead02: this.state.editList.attributes?.way_ahead?.o2,
            Ahead03: this.state.editList.attributes?.way_ahead?.o3,
            Ahead04: this.state.editList.attributes?.way_ahead?.o4,
            Conclusion: this.state.editList.attributes?.conclusion,
            summary1: this.state.editList.attributes?.way_ahead?.way_ahead_summary[0],
            summary2: this.state.editList.attributes?.way_ahead?.way_ahead_summary?.[1],
            incidents: this.state.editList.attributes?.analysis?.key_incidents
        }

        return <>
            <SidebarLayout {...this.props}>
                <PageContentLayout goBack={this.goBack} title={configJSON.AnalysisReport.NeedAnalysisReport}>
                    {this.state.isLoading ? (
                        <Loader loading={this.state.isLoading} />
                    ) : null}
                    {
                        this.state.readyForm &&
                        <EditForm
                            {...editPropsData}
                            mindId={this.state.mindId}
                            schoolId={this.state.schoolId}
                            handleFilterChange={this.handleInputChange}
                            courseFilter={this.state.selectedCourse.name}
                            schoolFilter={this.state.selectedSchool.name}
                            handleSchoolChange={this.handleSchoolInputChange}
                            handleAcademicChange = {this.handleAcademicYear}
                            academicYearLists={this.state.academicYearList}
                            academicYears={this.state.academicYear}
                            handleIntroductionChange={this.handleIntroduction}
                            handleSkilletChange={this.handleSkilledNeeded}
                            handleKeyIncidentChange={this.handleKeyIncident}
                            handleKeyConcernChange={this.handleKeyConcern}
                            handlePriorityModueChange={this.handlePriorityModule}
                            handleFocusAreaChange={this.handleFocusArea}
                            handleConclusionChange={this.handleConclusion}
                        />
                    }
                </PageContentLayout>
            </SidebarLayout>
        </>
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    diaContentEditNeed: {
        marginTop: "20px",
        textAlign: "center",
        fontWeight: 600,
        width: "36.181vw",
        fontSize: "1.667vw",
    } as React.CSSProperties,
    submitEditNeed: {
        width: "150px",
        background: "#0056A3",
        color: "#fff",
        padding: "10px",
        textTransform: "capitalize"
    } as React.CSSProperties,
    diaTitleEditNeed: {
        color: "#0056A3",
        textAlign: "center",
        fontWeight: 700,
        fontSize: "2.222vw",
        marginTop: "1vw",
        marginBottom: "1vw",
    } as React.CSSProperties,

}
// Customizable Area End
