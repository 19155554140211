import React from "react";
// Customizable Area Start
import { Box, Typography, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  workSheetIcon,
  playIcon,
  playWhiteIcon,
  documentsIcon,
} from "../../assets";
// Customizable Area End
const configJSON = require("../../config.js");

interface MaterialAttributes {
  title: string;
  trainer_name: string;
  uploaded_on: any;
  material_type: string;
  material_url: string;
  description?: string;
  document_url: string;
  material_thumbnail_url: string;
}

interface ParentingGuideAttributes {
  id: number;
  title: string;
  material_url: string;
  document_url: string;
  document_type: string;
  document_thumbnail_url: string;
}

interface MaterialItem {
  attributes: MaterialAttributes & ParentingGuideAttributes;
}
export interface ListItemProps {
  data: MaterialItem;
  isMaterialPage: boolean;
}

const useStyles = makeStyles<Theme, any>((theme) => ({
  // Customizable Area Start
  ListItemCard: {
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    border: "0.2px solid #e3e3e3",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "8px",
    cursor: "pointer",
    padding: "8px 13px",
    marginBottom: "13px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      padding: "0 0 10px 0 ",
      marginBottom: "30px",
    },
  },
  ListItemImgWrapper: {
    height: props => props.attachmentType === "video" ? "72px" : " 90px",
    width: props => props.attachmentType === "video" ? "162px" : " 90px",
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "30px",
      marginRight: "0",
      height: props => props.attachmentType === "video" ? "100%" : "90px",
      width: props => props.attachmentType === "video" ? "100%" : "90px",
    },
  },
  thumbnailImage: {
    height: props => props.attachmentType === "video" ? "72px" : "auto",
    width: props => props.attachmentType === "video" ? "162px" : "auto",
    borderRadius: "8px",
    [theme.breakpoints.down("xs")]: {
      borderTopRightRadius: "8px",
      borderTopLeftRadius: "8px",
      height: props => props.attachmentType === "video" ? "100%" : "auto",
      width: props => props.attachmentType === "video" ? "100%" : "auto",
    },
  },
  videoHideMaterial: {
    display: "none",
  },
  VideoPlayIconMaterial: {
    top: "50%",
    bottom: "50%",
    position: "absolute",
    transform: "translate(-2%, -50%)",
    width: "20px",
  },
  ListItemCardTitleMaterial: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    fontSize: "21px",
    lineHeight: "21px",
    fontWeight: 800,
    fontFamily: "Poppins",
    color: "#0056A3",
  },
  ListItemCardSubtitleMaterial: {
    opacity: 0.64,
    marginBottom: "5px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    color: "#3B3B3B",
    fontFamily: "Poppins",
    fontSize: "10px",
    lineHeight: "10px",
    fontWeight: 400,
  },
  cardContentWrapperMaterial: {
    flexDirection: "column",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      marginLeft: '0 !important'
    }
  },
  actionIconMaterial: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    marginRight: "8px",
    marginLeft: "auto",
    "& img": {
      width: "20px",
    },
  },
  // Customizable Area End
}));

const MaterialList = (props: ListItemProps) => {
  // Customizable Area Start
  const { data, isMaterialPage } = props;
  let attachmentUrl: string = data.attributes.document_url;
  let attachmentType: string = data.attributes.document_type;
  let thumbnail: string = data.attributes.document_thumbnail_url;
  let uploadedOn: string = "";
  let trainer: string = "";
  let title: string = data.attributes.title;
  if (isMaterialPage) {
    attachmentType = data.attributes.material_type;
    attachmentUrl = data.attributes.material_url;
    title = data.attributes.title;
    trainer = data.attributes.trainer_name;
    uploadedOn = data.attributes.uploaded_on;
    if (attachmentType === configJSON.Material.Video) {
      thumbnail = data.attributes.material_thumbnail_url;
    }
  }

  const classes = useStyles({ attachmentType });
  const handleClick = () => {
    if (attachmentType === configJSON.Material.Video) {
      const videoUrl = attachmentUrl;
      const newTab: any = window.open(
        `/VideoViewMaterial?videoUrl=${encodeURIComponent(videoUrl)}`,
        "_blank"
      );
      newTab.focus();
    } else if (attachmentType === "document") {
      const docUrl = `http://view.officeapps.live.com/op/embed.aspx?src=${attachmentUrl}`;
      const newTab = window.open(
        `/MaterialDetails?pdfUrl=${encodeURIComponent(
            attachmentUrl
          )}`,
        "_self"
      );
      newTab?.focus();
    } else {
      const newTab = window.open(
        `/MaterialDetails?pdfUrl=${
            attachmentUrl
          }`
        ,
        "_self"
      );
      newTab?.focus();
    }
  };

  const renderThumbnail = (attachmentType: string) => {
    switch (attachmentType) {
      case configJSON.Material.PDF:
        return documentsIcon;
      case configJSON.Material.XML:
        return documentsIcon;
      case null:
        return documentsIcon;
      case configJSON.Material.Document:
        return documentsIcon;
      case "video":
        return thumbnail;
      default:
        return workSheetIcon;
    }
  };
  let checkStyle =
    attachmentType === "video"
      ? { marginLeft: 0 }
      : { marginLeft: "70px" };
  
  return (
    <Box
      className={classes.ListItemCard}
      data-test-id="list-item"
      onClick={handleClick}
    >
      <Box className={classes.ListItemImgWrapper}>
        <img
          className={classes.thumbnailImage}
          src={renderThumbnail(attachmentType)}
          alt="Thumbnail"
        />
        {attachmentType === "video" && (
          <img
            className={classes.VideoPlayIconMaterial}
            src={playWhiteIcon}
            alt="Play Icon"
          />
        )}
      </Box>
      <Box className={classes.cardContentWrapperMaterial} style={checkStyle}>
        <Typography style={{ margin: uploadedOn ? '-18px 0 10px 0' : '0' }} className={classes.ListItemCardTitleMaterial}>
          {title}
        </Typography>
        {uploadedOn && <Typography className={classes.ListItemCardSubtitleMaterial}>
            {trainer} {uploadedOn}
        </Typography>}
      </Box>
      <Box className={classes.actionIconMaterial}>
        <img
          src={
            attachmentType === "video"
              ? playIcon
              : workSheetIcon
          }
          alt="Action Icon"
        />
      </Box>
    </Box>
    // Customizable Area End
  );
};

export default MaterialList;
